export const devFirebaseConfig = {
  apiKey: "AIzaSyATPx_YUK2JVIyO25aec2locd6xYKrXG58",
  authDomain: "snappi-dev-25d50.firebaseapp.com",
  projectId: "snappi-dev-25d50",
  storageBucket: "snappi-dev-25d50.appspot.com",
  messagingSenderId: "779667765768",
  appId: "1:779667765768:web:bcf2177a1ddcdb0b43efbd",
  measurementId: "G-96QH8HLX2V"
}

export const stagingFirebaseConfig = {
  apiKey: "AIzaSyCQRBfiu6BWjCiOXRR-OufSOkRZq2L17qA",
  authDomain: "snappi-staging.firebaseapp.com",
  projectId: "snappi-staging",
  storageBucket: "snappi-staging.appspot.com",
  messagingSenderId: "834128933201",
  appId: "1:834128933201:web:f55dbabe334bf1bb0e2370",
  measurementId: "G-WLZ4R35R5J"
};

export const firebaseConfig = {
  apiKey: "AIzaSyB0B1zbYcNizY7bQ5We7WgCsQAKdhHR_5Q",
  authDomain: "snappi-cde01.firebaseapp.com",
  projectId: "snappi-cde01",
  storageBucket: "snappi-cde01.appspot.com",
  messagingSenderId: "941354203964",
  appId: "1:941354203964:web:b40a6f2feed447e114bc39",
  measurementId: "G-WDDGHSB1ZV"
}