import { v4 as uuidv4 } from 'uuid'

import {
  collection,
  addDoc,
  doc,
  setDoc
} from "firebase/firestore"
import {
  db
} from ".."
import { COLLECTIONS } from '../utils/constants'
import {
  convertDateToUnixOfCountry
} from "../utils/helpers"
import uploadImage from "./uploadImage"

export default async function createDrop(brandId, drop, dropImages, activeStartDate) {
  try {
    if (dropImages.length > 0) {
      const promises = []
      dropImages.forEach(async (image) => {
        const uid = uuidv4()
        const path = `${brandId}/${uid}`
        const res = uploadImage({
          type: 'drops',
          path,
          image
        })

        promises.push(res)
      })
      const dropImageUrls = await Promise.all(promises)
      drop.dropImages = dropImageUrls.map((image) => image.url)
    }

    if (activeStartDate === 'else') {
      const unixStartDate = convertDateToUnixOfCountry(drop.dropDate, drop.brandRegion, false)
      drop.dropDate = unixStartDate * 1000
    } else {
      drop.dropDate = Date.now()
    }

    const allDropsRef = collection(db, COLLECTIONS.ALL_DROPS)
    const dropRes = await addDoc(allDropsRef, drop)

    const brandDropRef = doc(db, COLLECTIONS.BRANDS, brandId, COLLECTIONS.DROPS, dropRes.id)
    await setDoc(brandDropRef, drop)
  } catch (error) {
    console.log("Error creating drop:", error)
  }
}