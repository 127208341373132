import React, { createContext, useContext, useEffect, useState } from "react"
import getAllBrands from "../../actions/getAllBrands"
// import getLocalizedBrands from "../../actions/getLocalizedBrands"
// import allBrands from "../../utils/allBrands"

const BrandContext = createContext()
const BrandDispatchContext = createContext()

function BrandsProvider({ isFetchAll, children, isAdmin }) {
  const [allBrands, setAllBrands] = useState()

  useEffect(() => {
    async function fetchAllBrandsAdmin() {
      const allBrands = await getAllBrands()
      setAllBrands(allBrands)
    }

    isAdmin && fetchAllBrandsAdmin()
  }, [])

  if (window.location.hostname !== "snappi.club") console.log('brandsProvider', allBrands)

  return (
    <BrandContext.Provider value={allBrands}>
      <BrandDispatchContext.Provider value={setAllBrands}>
        {children}
      </BrandDispatchContext.Provider>
    </BrandContext.Provider>
  )
}

export { BrandsProvider, BrandContext, BrandDispatchContext }