import React, { useContext, useState } from 'react'
import cn from 'classnames'

import deactivateSale from '../../actions/deactivateSale'
import deleteSale from '../../actions/deleteSale'
import { checkIsPast, checkIsFuture, formatDate, getUrl } from '../../utils/helpers'
import loading from '../../assets/icons/loadingBlack.svg'
import whiteInfo from '../../assets/icons/whiteInfo.svg'

import style from "./style.module.css"
import { UserContext } from '../../providers/UserProvider'
import WithLink from '../WithLink'

export default function SaleTile(props) {
  const user = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)

  const {
    sale,
    activeSales,
    setActiveSales,
    setInactiveSales,
    isAdminDashboard,
    isInactive,
    isEditForm,
    isUserDashboard,
    setEditSale,
    isFirst
  } = props

  const {
    brandId,
    brandName,
    brandCover,
    brandUrl,
    brandLogo,
    brandRegion,

    saleId,
    saleUrl,
    saleHeader,
    saleCode,
    saleDescription,
    saleEndDate,
    saleStartDate,
    saleImage,
    salePercentage,
    saleIsActive,
    saleType
  } = sale

  const {
    userGender,
  } = user

  const start = formatDate(saleStartDate)
  const end = saleEndDate && formatDate(saleEndDate)

  const isFuture = checkIsFuture(saleStartDate)
  const isExpired = checkIsPast(saleEndDate)

  const startText = isFuture ? "Starts" : "Started"
  const endText = isExpired ? "Ended" : "Ends"

  const handleDeactivateSale = async () => {
    setIsLoading(true)

    await deactivateSale(brandId, saleId, brandRegion)
    const newActiveSales = activeSales.filter(sale => sale.saleId !== saleId)

    setActiveSales(newActiveSales)
    setInactiveSales?.(prev => [...prev, sale])
    setIsLoading(false)
  }

  const handleDeleteSale = async () => {
    setIsLoading(true)

    await deleteSale(brandId, saleId, saleIsActive)
    const method = saleIsActive ? setActiveSales : setInactiveSales
    method(prev => prev.filter(sale => sale.saleId !== saleId))

    setIsLoading(false)
  }

  const handleSetEditSale = () => setEditSale(sale)

  const url = getUrl(saleUrl, userGender, brandUrl)

  return (
    <WithLink
      url={!(isExpired || isAdminDashboard || isEditForm) && url}
      className={cn(
        style.saleContainer,
        isAdminDashboard && style.isAdminDashboard,
        isEditForm && style.isEditForm,
        isFirst && style.isFirst
      )}
    >
      <img
        src={saleImage || brandCover}
        alt="cover"
        className={style.salesImg}
      />

      <div className={style.saleInfo}>
        <div className={style.top}>
          {!isEditForm && (
            <WithLink
              url={!isUserDashboard && brandUrl}
              className={style.brandContainer}
            >
              <img
                src={brandLogo}
                className={style.logo}
                alt="logo"
              />

              {isAdminDashboard && <h3 className={style.brandRegion}>{brandRegion}</h3>}
              <h3>{brandName}</h3>
            </WithLink>
          )}

          <div className={style.dates}>
            {!isExpired && (
              <div className={style.date}>
                <p>{startText}</p>
                <p>{start}</p>
              </div>
            )}

            {end && (
              <div className={style.date}>
                <p>{endText}</p>
                <p>{end}</p>
              </div>
            )}
          </div>
        </div>

        <div className={style.saleDetails}>
          <div className={style.saleHeader}>
            {(salePercentage >= 0) &&
              <div className={cn(style.salePercentage, isInactive && style.inactive)}>
                <h5>{`Up to ${salePercentage}% off`}</h5>
              </div>
            }
            <h4>{saleHeader}</h4>
          </div>

          <p>{saleDescription}</p>
        </div>

        {(saleCode || saleType) && (
          <div className={style.saleTags}>
            {saleCode && (
              <div className={style.saleTag}>
                <p>USE CODE <span>{saleCode}</span></p>
              </div>
            )}

            {(saleType === "member" || saleType === "flash") && (
              <div className={cn(style.saleTag, style[saleType], saleType === "member" && style.memberToolTip)}>
                {saleType === "member" && <span className={style.toolTipText}>Member's events require signing into an account with the brand</span>}
                <p>{`${saleType} sale`} {saleType === "member" ? <span className={style.infoSpan}><img src={whiteInfo} alt="info" className={style.info} /></span> : ""}</p>
              </div>
            )}
          </div>
        )}
      </div>

      {(isAdminDashboard || isEditForm) && (
        <div className={style.actionsContainer}>
          <a
            href={url}
            target="_href"
            rel="noopener"
            className={cn(style.smallSaleTile_button, style.url)}
          >
            visit sale
          </a>

          {isEditForm && (
            <div
              className={cn(style.smallSaleTile_button, style.edit)}
              onClick={handleSetEditSale}
            >
              Edit
            </div>
          )}

          {!isInactive && (
            <div
              className={cn(style.smallSaleTile_button, style.deactivate)}
              onClick={handleDeactivateSale}
            >
              Deactivate
            </div>
          )}

          <div
            className={cn(style.smallSaleTile_button, style.delete)}
            onClick={handleDeleteSale}
          >
            Delete
          </div>

          {isLoading && (
            <div className={style.loading}>
              <img src={loading} className={style.loadingIcon} alt="loading" />
            </div>
          )}
        </div>
      )}
    </WithLink>
  )
}