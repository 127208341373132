import React, { useState } from 'react'
import cn from 'classnames'

import downarrow from '../../assets/icons/downTriangle.svg'
import style from "./style.module.css"

export default function DropDownMenu({ className, keyName, keyDisplayName, onClick, selectedValue, options }) {
  const [isActive, setIsActive] = useState(false)

  const handleCloseDropdowns = (e) => {
    e.preventDefault()
    setIsActive(false)
  }

  return (
    <>
      <div className={cn(className, style.dropdown, isActive && style.isActive)} onClick={() => setIsActive(prev => !prev)}>
        <img src={downarrow} className={style.downarrow} alt="arrow" />
        <p>
          {options.find(option => option.value === selectedValue)?.name || `Select ${keyDisplayName}`}
        </p>

        {isActive && (
          <div className={style.optionsContainer}>
            {options.map(({ name, value }) => (
              <div onClick={(e) => { e.preventDefault(); onClick(keyName, value) }} key={value}>
                {name}
              </div>
            ))}
          </div>
        )}
      </div>

      {isActive && (
        <div
          className={style.dropdownCancel}
          onClick={handleCloseDropdowns}
        />
      )}
    </>
  )
}