import {
  getFunctions,
  httpsCallable
} from "firebase/functions";

export default async function handleDeleteUserByAdmin(userId) {
  try {
    const functions = getFunctions()
    const deleteUserByAdmin = httpsCallable(functions, "deleteUserByAdmin")
    const res = await deleteUserByAdmin({ userId })
    return res.data
  } catch (error) {
    console.log("Error deleting user by admin:", error)
  }
}