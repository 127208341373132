import {
  doc,
  updateDoc
} from "firebase/firestore"
import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'
import { convertDateToUnixOfCountry, getTodaysDate } from "../utils/helpers"

export default async function deactivateCoupon(coupon, brandRegion) {
  try {
    const {
      brandId,
      couponId,
    } = coupon

    coupon.couponEndDate = convertDateToUnixOfCountry(getTodaysDate(), brandRegion, true)
    coupon.couponIsActive = false
    delete coupon.couponId

    const couponRef = doc(db, COLLECTIONS.BRANDS, brandId, COLLECTIONS.COUPONS, couponId)
    await updateDoc(couponRef, {
      couponEndDate: coupon.couponEndDate,
      couponIsActive: coupon.couponIsActive,
    })

    return coupon
  } catch (error) {
    console.log(error)
  }
}