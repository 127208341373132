import {
  doc,
  updateDoc
} from "firebase/firestore"
import {
  db
} from ".."
import { COLLECTIONS } from '../utils/constants'
import { convertDateToUnixOfCountry } from "../utils/helpers"

const checkIsUnixTime = (time) => time.toString().length === 13

export default async function updateSale(brandId, updatedSale) {
  try {
    const {
      brandRegion,
    } = updatedSale

    // TODO: NOT THE BEST CHECK IF IT IS UNIX TIME
    // CHECK IF NUMBER HAS BEEN ALTERED BY DATE PICKER (OUTPUT: 3-16-2023)
    if (!checkIsUnixTime(updatedSale.saleStartDate)) {
      const unixStartDate = convertDateToUnixOfCountry(updatedSale.saleStartDate, brandRegion, false)
      updatedSale.saleStartDate = unixStartDate * 1000
    }

    // TODO: NOT THE BEST CHECK IF IT IS UNIX TIME
    // CHECK IF NUMBER HAS BEEN ALTERED BY DATE PICKER (OUTPUT: 3-16-2023)
    if (!checkIsUnixTime(updatedSale.saleStartDate)) {
      // get brandRegion code from brand
      const unixEndDate = convertDateToUnixOfCountry(updatedSale.saleEndDate, brandRegion, true)
      updatedSale.saleEndDate = unixEndDate * 1000
    }

    if (updatedSale.saleIsActive) {
      const activeSale = doc(db, COLLECTIONS.ACTIVE_SALES, updatedSale.saleId)
      await updateDoc(activeSale, updatedSale)
    }

    const brandRef = doc(db, COLLECTIONS.BRANDS, brandId, COLLECTIONS.SALES, updatedSale.saleId)
    await updateDoc(brandRef, updatedSale)
  } catch (error) {
    console.log("Error updating sale:", error)
  }
}