import {
  collection,
  getDocs,
  query,
  orderBy
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'

export default async function getCoupons(brandId) {
  const collectionRef = query(collection(db, COLLECTIONS.BRANDS, brandId, COLLECTIONS.COUPONS), orderBy("couponStartDate", "desc"))
  const querySnapshot = await getDocs(collectionRef)

  const coupons = []
  querySnapshot.forEach((doc) => {
    const data = doc.data()
    // PROCESS BY WHICH COUPON IS SAVED LEAVES ID OUTSIDE OF DATA
    data.couponId = doc.id
    coupons.push(data)
  })

  const activeCoupons = []
  const inactiveCoupons = []
  coupons.forEach(coupon => {
    if (coupon.couponIsActive) activeCoupons.push(coupon)
    else inactiveCoupons.push(coupon)
  })

  return { activeCoupons, inactiveCoupons }
}