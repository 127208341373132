import React from 'react'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'

import style from "./style.module.css"
import { routes } from '../../utils/constants'

export default function BrandBar({ brand, setModal, setBrands }) {
  const navigate = useNavigate()
  const { brandName, brandLogo, brandId, brandRegion } = brand || {}

  const handleTileClick = () => navigate(`${routes.ALL_BRANDS}/${brandId}`)

  const handleModal = (e, type) => {
    e.stopPropagation()
    setModal({ isOpen: true, type, content: brand, functions: { setBrands } })
  }

  return (
    <div onClick={handleTileClick} className={cn(style.bar)}>
      <div className={style.company}>
        <img src={brandLogo} className={style.logo} alt={`${brandName}'s logo`} />
        <h4>{brandRegion}</h4>
        <h4>{brandName}</h4>
      </div>

      <div className={style.buttons}>
        <div onClick={(e) => handleModal(e, "createSaleForm")}>
          <p>
            Create Sale
          </p>
        </div>
        <div onClick={(e) => handleModal(e, "editSaleForm")}>
          <p>
            Edit Sale
          </p>
        </div>
        <div onClick={(e) => handleModal(e, "createDropForm")}>
          <p>
            Create Drop
          </p>
        </div>
        <div onClick={(e) => handleModal(e, "editCoupons")}>
          <p>
            Edit Coupons
          </p>
        </div>
        <div onClick={(e) => handleModal(e, "editBrandForm")}>
          <p>
            Edit Brand
          </p>
        </div>
      </div>
    </div>
  )
}