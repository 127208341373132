import {
  collection,
  query,
  getDocs,
  orderBy,
  where
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS } from '../utils/constants'

export default async function getMyRecentDrops(brandIds, userLastLogin) {
  const activeCoupons = []
  const maxLength = 10
  const splitBrands = []

  const now = new Date()

  const twoWeeksAgoDate = new Date(now.getTime() - (14 * 24 * 60 * 60 * 1000))
  const twoWeeksAgo = Math.floor(twoWeeksAgoDate.getTime())

  const twoWeeksOrLastLogin = Math.max(twoWeeksAgo, userLastLogin)

  const threeDaysAgoDate = new Date(now.getTime() - (3 * 24 * 60 * 60 * 1000))
  const threeDaysAgo = Math.floor(threeDaysAgoDate.getTime())

  const cutoffTime = Math.min(threeDaysAgo, twoWeeksOrLastLogin)

  for (let i = 0; i < brandIds.length; i += maxLength) {
    splitBrands.push(brandIds.slice(i, i + maxLength))
  }

  const allDropsRef = collection(db, COLLECTIONS.ALL_DROPS)

  const resultPromises = []
  splitBrands.forEach(async (brandIds) => {
    // QUERY CANNOT HAVE MORE THAN TEN BRAND IDS IN OPERATOR
    const q = query(
      allDropsRef,
      orderBy("dropDate", "desc"),
      where("dropDate", ">", cutoffTime),
      where("brandId", "in", [...brandIds])
    )
    const docs = getDocs(q)
    resultPromises.push(docs)
  })

  const queries = await Promise.all(resultPromises)
  queries.forEach((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const data = doc.data()
      data.saleId = doc.id
      activeCoupons.push(data)
    })
  })

  return activeCoupons
}