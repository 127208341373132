import React from 'react'

import style from "./style.module.css"
import Button from '../Button'

const CancelSubscriptionForm = ({ functions: { confirmCancelSubscription } }) => {
  return (
    <div className={style.form}>
      <h3>Are you sure you want to cancel?</h3>

      <Button onClick={confirmCancelSubscription}>
        Cancel Subscription
      </Button>
    </div>
  )
}

export default CancelSubscriptionForm