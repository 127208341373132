import { useEffect, useState, useCallback, useContext } from 'react';
import { NotificationDispatchContext } from '../providers/NotificationProvider';

export default function useCopyToClipboard(text, notifyTimeout = 2500) {
  const setNotifications = useContext(NotificationDispatchContext)
  const [copyStatus, setCopyStatus] = useState('inactive')
  const copy = useCallback((copiedText) => {
    navigator.clipboard.writeText(copiedText).then(
      () => {
        setCopyStatus('copied')
        setNotifications({
          type: 'success',
          message: `Copied ${copiedText} to clipboard`,
          fadeout: true,
          isActive: true,
        })
      },
      () => setCopyStatus('failed'),
    )
  }, [text])

  useEffect(() => {
    if (copyStatus === 'inactive') return

    const timeoutId = setTimeout(() => setCopyStatus('inactive'), notifyTimeout)

    return () => clearTimeout(timeoutId)
  }, [copyStatus])

  return copy
}