import { v4 as uuidv4 } from 'uuid'

import {
  collection,
  addDoc,
  doc,
  setDoc
} from "firebase/firestore"
import {
  db
} from ".."
import { COLLECTIONS } from '../utils/constants'
import {
  checkIsFuture,
  convertDateToUnixOfCountry
} from "../utils/helpers"
import uploadImage from "./uploadImage"

export default async function createSale(brandId, sale, saleImage, activeStartDate) {
  const {
    brandRegion,
  } = sale

  try {
    if (saleImage) {
      const uid = uuidv4()
      const { url } = await uploadImage({
        type: 'sales',
        path: `${brandId}-${uid}`,
        image: saleImage
      })

      sale.saleImage = url
    }

    if (activeStartDate === 'else') {
      const unixStartDate = convertDateToUnixOfCountry(sale.saleStartDate, brandRegion, false)
      sale.saleStartDate = unixStartDate * 1000
    } else {
      sale.saleStartDate = Date.now()
    }

    if (sale.saleEndDate) {
      // get brandRegion code from brand
      const unixEndDate = convertDateToUnixOfCountry(sale.saleEndDate, brandRegion, true)
      sale.saleEndDate = unixEndDate * 1000
    }

    sale.saleIsActive = !sale.saleEndDate || checkIsFuture(sale.saleEndDate)

    const activeSale = collection(db, COLLECTIONS.ACTIVE_SALES)
    const saleRes = await addDoc(activeSale, sale)

    const brandRef = doc(db, COLLECTIONS.BRANDS, brandId, COLLECTIONS.SALES, saleRes.id)
    await setDoc(brandRef, sale)
  } catch (error) {
    console.log("Error creating sale:", error)
  }
}