function cleanBrand(brand) {
  const cleanedBrand = {
    brandId: brand.brandId,
    brandUrl: brand.brandUrl,
    brandRegion: brand.brandRegion,
    brandLogo: brand.brandLogo,
    brandCover: brand.brandCover,
    brandName: brand.brandName,
    ...(brand.brandDescription && { brandDescription: brand.brandDescription }),
  }

  return cleanedBrand
}

export default cleanBrand
