import React, { createContext, useState } from "react"

// ModalContext: to query the context state
// ModalDispatchContext: to mutate the context state
const ModalContext = createContext(undefined)
const ModalDispatchContext = createContext(undefined)

function ModalProvider({ children }) {
  const [modal, setModal] = useState({
    isOpen: false,
    type: '',
    content: null,
    functions: {}
  })

  return (
    <ModalContext.Provider value={modal}>
      <ModalDispatchContext.Provider value={setModal}>
        {children}
      </ModalDispatchContext.Provider>
    </ModalContext.Provider>
  )
}

export { ModalProvider, ModalContext, ModalDispatchContext }