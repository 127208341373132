import React, { useContext } from 'react'
import cn from 'classnames'

import deleteSale from '../../actions/deleteSale'
import { formatDate } from '../../utils/helpers'

import style from "./style.module.css"
import WithLink from '../WithLink'
import BaseTile from '../BaseTile'

export default function DropTile(props) {
  const {
    drop,
    isFirst,
    isAdminDashboard,
    isInactive,
    isEditForm,
    isBrandDashboard,
    isUserDashboard,
    gender
  } = props

  const {
    brandId,
    brandUrl,
    brandLogo,
    brandName,
    brandRegion,
    dropDate,
    dropHeader,
    dropDescription,
    dropUrl,
    dropImages,
    dropType
  } = drop

  const brand = {
    brandId,
    brandUrl,
    brandLogo,
    brandName,
    brandRegion,
  }

  const start = formatDate(dropDate)

  // const handleDeleteSale = async () => {
  // await deleteSale(brandId, saleId)
  // setActiveSales(prev => prev.filter(drop => drop.saleId !== saleId))
  // }

  const dropUrlToUse = dropUrl?.[gender] || dropUrl?.general || brandUrl

  return (
    <BaseTile
      brand={brand}
      date={start}
      className={cn(style.dropTile, isFirst && style.isFirst)}
      contentUrl={dropUrlToUse}
    >
      <div className={style.top}>
        <div className={style.main}>
          <div className="columnStart">
            <div className="rowCenter">
              <div className={cn(style.tag, dropType === "product" && style.productTag)}>
                <h5>{dropType}</h5>
              </div>

              <h4>{dropHeader}</h4>
            </div>
            <p>{dropDescription}</p>
          </div>
        </div>
      </div>

      <div className={style.carouselContainer}>
        <div className={style.carousel}>
          {dropImages.map(url => <img src={url} alt="drop" key={url} className={cn(dropImages.length === 1 && style.isOnlyImage)} />)}
          <div className={style.carouselPadding} />
        </div>
      </div>

      {/* {isAdminDashboard && (
        <div className={style.actionsContainer}>
          <a href={dropUrlToUse} target="_href" rel="noopener">
            drop url
          </a>

          <div className={style.smallSaleTile_button} onClick={handleDeleteSale}>
            Delete
          </div>
        </div>
      )} */}
    </BaseTile>
  )
}