import {
  doc,
  setDoc
} from "firebase/firestore"
import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'

const createUser = async (user) => {
  try {
    const userRef = doc(db, COLLECTIONS.USERS, user.userId)
    const res = await setDoc(userRef, user)
    return res
  } catch (error) {
    console.log("Error creating user:", error)
  }
}

export default createUser