import React, { useContext, useState } from 'react'
import cn from 'classnames'

import { formatDate } from '../../utils/helpers'

import style from "./style.module.css"

export default function DropContent({ drop }) {
  const {
    brandCover,
    dropUrl,
    dropImages,
    dropDate,
    dropHeader,
    dropType
  } = drop || {}

  const dropped = formatDate(dropDate)

  return (
    <>
      <img
        src={dropImages?.[0] || brandCover}
        alt="cover"
        className={style.cardImage}
      />

      <p className={style.startDate}>{`DROPPED ${dropped}`}</p>
      <h3 className={style.cardHeader}>{dropHeader}</h3>

      <div className={cn(style.cardTag, dropType === "product" ? style.productTag : style.collectionTag)}>
        <p>
          {dropType}
        </p>
      </div>
    </>
  )
}