import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import cn from 'classnames'

import getBrand from '../../actions/getBrand'
import allBrands from '../../utils/allBrands'
import getSales from '../../actions/getSales'
import getCoupons from '../../actions/getCoupons'

import { UserContext } from '../../providers/UserProvider'
import Page from '../../components/Page'
import SaleTile from '../../components/SaleTile'
import FollowButton from '../../components/FollowButton'
import BrandNugget from '../../components/BrandNugget'
import { BrandContext, BrandDispatchContext } from '../../providers/BrandsProvider'
import loading from '../../assets/icons/loadingBlack.svg'

// import unlikeBrand from '../../actions/unlikeBrand'
// import heart from '../../assets/icons/heart.svg'
import style from "./style.module.css"
import CouponTile from '../../components/CouponTile'
import getDrops from '../../actions/getDrops'
import DropTile from '../../components/DropTile'
import FeedTabs from '../../components/FeedTabs'
import Button from '../../components/Button'
import WithLink from '../../components/WithLink'

export default function BrandDashboard() {
  const params = useParams()
  const user = useContext(UserContext)
  const setBrands = useContext(BrandDispatchContext)
  const brands = useContext(BrandContext)

  const [brand, setBrand] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [activeCoupons, setActiveCoupons] = useState([])
  const [drops, setDrops] = useState([])
  const [activeTab, setActiveTab] = useState("all")

  const { brandUid } = params
  const {
    brandDescription,
    brandName,
    brandCover,
    brandLogo,
    sales,
    coupons,
    brandUrl,
    biggestDiscount,
    hasMembershipProgram
  } = brand || {}

  const { activeSales = [], inactiveSales = [] } = sales || {}
  const { userRegion = "us" } = user || {}
  const similarBrands = userRegion && [allBrands[userRegion]["cos"], allBrands[userRegion]["paulSmith"], allBrands[userRegion]["y3"], allBrands[userRegion]["adidas"]]

  useEffect(() => {
    async function fetchData() {
      setBrand()
      setIsLoading(true)

      let fetchedBrand

      if (brands?.[brandUid]?.fetched) fetchedBrand = brands[brandUid]
      else fetchedBrand = await getBrand(brandUid)

      // GET SALES
      const sales = {}
      const allSales = await getSales(brandUid)
      const activeSales = []
      const inactiveSales = []
      allSales.forEach(sale => {
        if (sale.saleIsActive) activeSales.push(sale)
        else inactiveSales.push(sale)
      })
      sales.allSales = allSales
      sales.activeSales = activeSales
      sales.inactiveSales = inactiveSales
      fetchedBrand.sales = sales

      // GET DISCOUNT CODES
      const { activeCoupons, inactiveCoupons } = await getCoupons(brandUid)
      setActiveCoupons(activeCoupons)
      fetchedBrand.coupons = coupons

      // GET DROPS
      const drops = await getDrops(brandUid)
      setDrops(drops)

      fetchedBrand.fetched = true
      setBrand(fetchedBrand)
      setBrands(prevBrands => ({
        ...prevBrands,
        [brandUid]: fetchedBrand,
      }))
      setIsLoading(false)
    }

    fetchData()
  }, [brandUid])

  // const handleLikeBrand = async () => {
  //   if (isLiked) await unlikeBrand({ userId, brand })
  //   else await likeBrand({ userId, brand })
  // }

  const hasNoContent = !isLoading && !brand?.sales?.activeSales?.length && !brand?.coupons?.activeCoupons?.length && !drops?.length

  return (
    <Page contentClassName={style.content} pageClassName={style.page}>
      <div className={style.dashboard_left}>
        <section className={cn(style.widget, style.brand_general, style.container)}>
          <div className={style.top}>
            <div className={style.brand_general_pics}>
              {brandCover ? <img src={brandCover} alt="brand cover" className={style.cover} />
                : <div className={style.cover} />}
              {brandLogo ? <img src={brandLogo} className={style.brandLogo} alt="brand logo" />
                : <div className={style.brandLogo} />}

              {isLoading && (
                <div className={style.brandLogo}>
                  <img
                    src={loading}
                    alt="loading"
                    className={style.loading}
                  />
                </div>
              )}
            </div>

            <div className={cn(style.widget_section_left_justify, style.name)}>
              <h1>{brandName}</h1>
              <p className={style.brandDescription}>{brandDescription}</p>

              {!isLoading && (
                <div className={style.insider}>
                  <div className={style.insiderRow}>
                    <p>WEBSITE</p>
                    <a href={brandUrl}>{brandUrl}</a>
                  </div>

                  {activeSales.length > 0 && (
                    <div className={cn(style.insiderRow, style.row)}>
                      <p>ON SALE NOW</p>
                      <svg>
                        <circle fill="#0fd336" cx="6" cy="6" stroke="none" r="6">
                          <animate attributeName="opacity" dur="2s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
                        </circle>
                      </svg>
                    </div>
                  )}

                  {/* <div className={style.insiderRow}>
                    <p>COUNTRY</p>
                    <p>England</p>
                  </div> */}

                  {/* <div className={style.insiderRow}>
                    <p>COMPANY SIZE</p>
                    <p>Medium</p>
                  </div> */}

                  {biggestDiscount && (
                    <div className={style.insiderRow}>
                      <p>BIGGEST DISCOUNT</p>
                      <p>30%</p>
                    </div>
                  )}

                  {hasMembershipProgram && (
                    <div className={style.insiderRow}>
                      <p>MEMBERSHIP PROGRAM</p>
                      <p>Offered</p>
                    </div>
                  )}

                  {/* <div className={style.insiderRow}>
                    <p>APP</p>
                    <p>Offered</p>
                  </div> */}
                </div>
              )}
            </div>

            {isLoading && (
              <img
                src={loading}
                alt="loading"
                className={style.loading}
              />
            )}
          </div>

          <div className={style.action}>
            {/* <div onClick={handleLikeBrand} className={cn(style.likeButton, isLiked && style.isLiked)}>
            <img src={heart} alt="like" />
          </div> */}
            <FollowButton brand={brand} isBrandDashboard className={style.followButton} />

            <WithLink url={brandUrl}>
              <Button>
                Shop
              </Button>
            </WithLink>
          </div>
        </section>

        {/* <section className={cn(style.widget, style.brand_similar)}>
          <div className={style.widget_title}>
            <p>Similar Brands</p>
          </div>

          <div className={style.widget_section_center_justify}>
            <div className={style.brand_similar_grid}>
              {similarBrands?.length > 0 && similarBrands?.map(brand => <BrandNugget brand={brand} isSelected key={brand?.brandId} />)}
            </div>
          </div>
        </section> */}
      </div >

      <div className={style.dashboard_right}>
        <section className={cn(style.widget, style.fullWidth)}>
          <FeedTabs activeTab={activeTab} setActiveTab={setActiveTab} country={user.userRegion} />
        </section>

        {(
          isLoading ||
          (activeCoupons.length > 0 && activeTab === "all") ||
          activeTab === "coupons" ||
          hasNoContent
        ) && (
            <section className={cn(style.widget, style.fullWidth, style.container)}>
              <div className={style.widget_title}>
                <p>{`Active ${userRegion === "uk" ? "Promo Codes" : "Coupons"}`}</p>
              </div>

              {!isLoading && (
                <div className={cn(style.coupon_container)}>
                  {activeCoupons.map(coupon => (
                    <CouponTile
                      coupon={coupon}
                      key={`${coupon.couponStartDate}-${coupon.couponDescription}`}
                      isBrandDashboard
                    />
                  ))}

                  {activeCoupons.length === 0 && (
                    <div className={cn(style.emptySalesContainer, style.emptyCouponsContainer, style.leftCoupon)}>
                      <div className={style.emptySales}>
                        <p>{`NO ACTIVE ${userRegion === "uk" ? "PROMO CODES" : "COUPONS"}`}</p>
                      </div>
                    </div>
                  )}

                  {activeCoupons.length === 0 && (
                    <div className={cn(style.emptySalesContainer, style.emptyCouponsContainer)}>
                      <div className={style.emptySales}>
                        <p>{`NO ACTIVE ${userRegion === "uk" ? "PROMO CODES" : "COUPONS"}`}</p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {isLoading && (
                <img
                  src={loading}
                  alt="loading"
                  className={style.loading}
                />
              )}
            </section>
          )}

        {(
          isLoading ||
          (activeSales.length > 0 && activeTab === "all") ||
          activeTab === "sales" ||
          hasNoContent
        ) && (
            <section className={cn(style.widget, style.fullWidth, style.container)}>
              <div className={style.widget_title}>
                <p>Active Sales</p>
              </div>

              {!isLoading && (
                <div className={cn(style.sales_container)}>
                  {activeSales.map(a => (
                    <SaleTile
                      sale={a}
                      key={`${a.saleStartDate}-${a.saleHeader}`}
                      isBrandDashboard
                    />
                  ))}

                  {activeSales.length === 0 && (
                    <div className={style.emptySalesContainer}>
                      <div className={style.emptySales}>
                        NO ACTIVE SALES
                      </div>
                    </div>
                  )}
                </div>
              )}

              {isLoading && (
                <img
                  src={loading}
                  alt="loading"
                  className={style.loading}
                />
              )}
            </section>
          )}

        {(
          isLoading ||
          (drops.length > 0 && activeTab === "all") ||
          activeTab === "drops" ||
          hasNoContent
        ) && (
            <section className={cn(style.widget, style.fullWidth, isLoading && style.container)}>
              <div className={style.widget_title}>
                <p>Recent Drops</p>
              </div>

              {!isLoading && (
                <div className={cn(style.sales_container)}>
                  {drops.map((d, i) => (
                    <DropTile
                      drop={d}
                      key={d.dropDate}
                      isBrandDashboard
                      isFirst={i === 0}
                    />
                  ))}

                  {drops.length === 0 && (
                    <div className={style.emptySalesContainer}>
                      <div className={style.emptySales}>
                        NO RECENT DROPS
                      </div>
                    </div>
                  )}
                </div>
              )}

              {isLoading && (
                <img
                  src={loading}
                  alt="loading"
                  className={style.loading}
                />
              )}
            </section>
          )}

        {(
          isLoading ||
          (activeTab === "all" && inactiveSales.length > 0) ||
          activeTab === "sales" ||
          hasNoContent
        ) && (
            <section className={cn(style.widget, style.fullWidth, style.container)}>
              <div className={style.widget_title}>
                <p>Past Sales</p>
              </div>

              {!isLoading && (
                <div className={cn(style.sales_container)}>
                  {inactiveSales.map(a => (
                    <SaleTile
                      sale={a}
                      key={`${a.saleStartDate}-${a.saleHeader}`}
                      isBrandDashboard
                      isInactive
                    />
                  ))}

                  {inactiveSales.length === 0 && (
                    <div className={style.emptySalesContainer}>
                      <div className={style.emptySales}>
                        NO PAST SALES
                      </div>
                    </div>
                  )}
                </div>
              )}

              {isLoading && (
                <img
                  src={loading}
                  alt="loading"
                  className={style.loading}
                />
              )}
            </section>
          )}
      </div>
    </Page >
  )
}