import {
  doc,
  collectionGroup,
  query,
  where,
  getDocs,
  updateDoc
} from "firebase/firestore"
import {
  db
} from ".."
import { COLLECTIONS } from '../utils/constants'

const cleanBrandInfo = (brand) => {
  const cleanedBrand = {
    // DO NOT INCLUDE BRANDID, CANNOT BE CHANGED
    brandLogo: brand.brandLogo,
    brandCover: brand.brandCover,
    brandName: brand.brandName,
    brandRegion: brand.brandRegion,
    brandUrl: brand.brandUrl,
    ...(brand.brandDescription && { brandDescription: brand.brandDescription }),
    ...(brand.brandDepartments && { brandDepartments: brand.brandDepartments }),
  }

  return cleanedBrand
}

const updateBrand = async (nextBrand) => {
  try {
    const {
      brandId
    } = nextBrand

    const cleanedBrand = cleanBrandInfo(nextBrand)
    const updatePromises = []

    // UPDATE BRAND INFO
    const brandRef = doc(db, COLLECTIONS.BRANDS, brandId)
    const updateRes = updateDoc(brandRef, cleanedBrand)

    // UPDATE ALL INSTANCES OF BRAND IN USERS FOLLOWING
    const followerRef = collectionGroup(db, COLLECTIONS.FOLLOWING)
    const queryDoc = query(followerRef, where("brandId", "==", brandId))
    const followers = await getDocs(queryDoc)
    followers.forEach(async (doc) => {
      const promise = updateDoc(doc.ref, cleanedBrand)
      updatePromises.push(promise)
    })

    await Promise.all([...updatePromises, updateRes])
  } catch (error) {
    console.log("Error updating brand:", error)
  }
}

export default updateBrand