import React, { useContext } from 'react'
import cn from 'classnames'

import deactivateSale from '../../actions/deactivateSale'
import deleteSale from '../../actions/deleteSale'
import { checkIsPast, checkIsFuture, formatDate } from '../../utils/helpers'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'

import style from "./style.module.css"

export default function CouponTile(props) {
  const copy = useCopyToClipboard()

  const {
    coupon,
    activeSales,
    setActiveSales,
    setInactiveSales,
    isAdminDashboard,
    isInactive,
    isEditForm,
    isBrandDashboard,
    isUserDashboard
  } = props

  const {
    brandId,
    couponEndDate,
    couponStartDate,
    couponDescription,
    saleUrl,
    brandUrl,
    brandLogo,
    brandName,
    saleId,
    brandCountry,
    couponCode,
    couponPercent
  } = coupon

  const start = formatDate(couponStartDate)
  const end = couponEndDate && formatDate(couponEndDate)

  const isFuture = checkIsFuture(couponStartDate)
  const isExpired = checkIsPast(couponEndDate)

  const startText = isFuture ? "Starts" : "Activated"
  const endText = isExpired ? "Ended" : "Ends"

  const handleDeactivateSale = async () => {
    await deactivateSale(brandId, saleId, brandCountry)

    const newActiveSales = activeSales.filter(coupon => coupon.saleId !== saleId)
    setActiveSales(newActiveSales)
    setInactiveSales?.(prev => [...prev, coupon])
  }

  const handleDeleteSale = async () => {
    await deleteSale(brandId, saleId)
    setActiveSales(prev => prev.filter(coupon => coupon.saleId !== saleId))
  }

  return (
    <div
      className={cn(
        style.container,
        isAdminDashboard && style.isAdminDashboard,
        isBrandDashboard && style.isBrandDashboard,
        isUserDashboard && style.isUserDashboard,
        isEditForm && style.isEditForm
      )}
      onClick={() => copy(couponCode)}
    >
      <div className={style.saleInfo}>
        <div className={style.top}>
          {(!isEditForm && !isBrandDashboard) && (
            <a href={brandUrl} className={style.brandContainer} target="_href" rel="noopener">
              <img
                src={brandLogo}
                className={style.logo}
                alt="logo"
              />

              {isAdminDashboard && <h3 className={style.brandCountry}>{brandCountry}</h3>}
              <h3>{brandName}</h3>
            </a>
          )}

          <div className={style.dates}>
            <div className={style.date}>
              <p>{startText}</p>
              <p>{start}</p>
            </div>

            {end && (
              <div className={style.date}>
                <p>{endText}</p>
                <p>{end}</p>
              </div>
            )}
          </div>
        </div>

        <div className={style.details}>
          <div className={style.header}>
            {couponPercent && (
              <div className={cn(style.couponPercent, isInactive && style.inactive)}>
                <h5>{`${couponPercent}%`}</h5>
              </div>
            )}
            <h4>{couponCode}</h4>
          </div>

          <p>{couponDescription}</p>
        </div>
      </div>

      {isAdminDashboard && (
        <div className={style.actionsContainer}>
          <a href={saleUrl} target="_href" rel="noopener">
            coupon url
          </a>
          {!isInactive && (
            <div className={style.smallSaleTile_button} onClick={handleDeactivateSale}>
              Deactivate
            </div>
          )}
          <div className={style.smallSaleTile_button} onClick={handleDeleteSale}>
            Delete
          </div>
        </div>
      )}
    </div>
  )
}