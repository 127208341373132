import React, { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import {
  onAuthStateChanged,
} from "firebase/auth"

import { auth } from "../.."
import getMe from "../../actions/getMe"
import { NotificationDispatchContext } from "../NotificationProvider"
import { routes } from "../../utils/constants"
import updateLastLogin from "../../actions/updateLastLogin"

const UserContext = createContext(undefined)
const UserDispatchContext = createContext(undefined)

function UserProvider({ children }) {
  const setNotifications = useContext(NotificationDispatchContext)
  const navigate = useNavigate()

  const [user, setUser] = useState({
    userId: null,
    isLoaded: false,
    userEmail: '',
    isAdmin: '',
    userName: '',
    userStatus: '',
    following: {},
    liked: {},
  })

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (!authUser) return setUser({ isLoaded: true, userId: null })

      // GET USER
      const { email, uid: userId } = authUser || {}
      const user = await getMe(userId)
      setUser({
        ...user,
        isLoaded: true,
        userEmail: email,
        userId,
        pastUserLastLogin: user.userLastLogin,
      })

      // HANDLE USER STATUS / ONBOARDING
      const { userStatus, userOnboarding } = user
      const notification = {}
      if (userStatus === "incomplete" || userStatus === "past_due") {
        notification.type = "error"
        notification.type = "billing"
        notification.message = userStatus === "incomplete" ? "Payment failed, update payment method" : "Payment failed, update payment method"
        notification.redirect = routes.UPDATE_PAYMENT
        notification.isDismissable = false
        notification.isActive = true
      }
      setNotifications(notification)

      await updateLastLogin(userId)

      if (userOnboarding) navigate(routes.ONBOARD_FOLLOW)
    })

    return () => unsubscribe()
  }, [])

  if (window.location.hostname !== "snappi.club") console.log('userProvider', user)

  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={setUser}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  )
}

export { UserProvider, UserContext, UserDispatchContext }