import {
  getFunctions,
  httpsCallable
} from "firebase/functions"
import cleanBrand from "../utils/cleanBrandES6.js"

export default async function handleUpdateBatchFollowBrands({
  nextFollowing: nextFollowingObject,
  nextMethod,
  nextPhone,
  setUser,
  nextAlertPreference,
  isOnboarded
}) {
  try {
    const functions = getFunctions()
    const updateBatchFollowBrands = httpsCallable(functions, "updateBatchFollowBrands")
    const nextFollowing = Object.values(nextFollowingObject).map(brand => cleanBrand(brand))

    const res = await updateBatchFollowBrands({
      nextFollowing,
      nextMethod,
      nextPhone,
      nextAlertPreference,
      isOnboarded
    })

    setUser(prevUser => ({
      ...prevUser,
      following: nextFollowingObject,
      nextFollowing: {},
    }))

    return res.data
  } catch (error) {
    console.log("Error following brands:", error)
  }
}