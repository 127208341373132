import React, { useContext, useRef, useState } from 'react'
import cn from "classnames"

import Button from '../Button'
import { ModalDispatchContext } from '../../providers/ModalProvider'
// import createBrand from '../../actions/createBrand'
import updateBrand from '../../actions/updateBrand'
import uploadImage from '../../actions/uploadImage'
import downTriangle from '../../assets/icons/downTriangle.svg'

import style from "./style.module.css"
import createBrand from '../../actions/createBrand'

// DIFFERENT TYPES: createBrandForm, editBrandForm
export default function UpdateBrandForm({ type, brand, functions }) {
  const coverRef = useRef()
  const logoRef = useRef()
  const [updatedBrand, setUpdatedBrand] = useState(brand)
  const [newLogo, setNewLogo] = useState()
  const [newCover, setNewCover] = useState()
  const [isLoading, setIsLoading] = useState()
  const [isInsiderCollapsed, setInsiderCollapsed] = useState(true)
  const [previews, setPreviews] = useState({ cover: null, logo: null })
  const { setBrands } = functions || {}

  const {
    brandUrl,
    brandName,
    brandDescription,
    brandLogo,
    brandCover,
    brandRegion,
  } = updatedBrand || {}

  const setModal = useContext(ModalDispatchContext)

  const setUpdatedBrandValue = (name, value) => {
    setUpdatedBrand({
      ...updatedBrand,
      [name]: value,
    })
  }

  const handlePicInput = (e, func, type) => {
    func(e.target.files[0])

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setPreviews(prev => ({ ...prev, [type]: reader.result }));
    });
    reader.readAsDataURL(e.target.files[0]);
  }

  const handleSyntheticClick = (ref) => ref.current.click()

  const handleUpdateBrand = async () => {
    setIsLoading(true)
    const nextBrand = { ...updatedBrand }

    if (newCover) {
      const { url } = await uploadImage({
        type: 'cover',
        path: brand?.brandId || updatedBrand.brandId,
        image: newCover
      })

      nextBrand.brandCover = url
    }

    if (newLogo) {
      const { url } = await uploadImage({
        type: 'logo',
        path: brand?.brandId || updatedBrand.brandId,
        image: newLogo
      })

      nextBrand.brandLogo = url
    }

    if (!nextBrand.brandRegion) nextBrand.brandRegion = 'us'

    const method = type === 'createBrandForm' ? createBrand : updateBrand
    await method(nextBrand)

    setUpdatedBrand({})
    setModal({ isOpen: false })
    setBrands(prevBrands => ({
      ...prevBrands,
      [brand?.brandId || updatedBrand.brandId]: nextBrand
    }))
    setIsLoading(false)
  }

  let onSaveText
  let formHeader

  switch (type) {
    case 'createBrandForm':
      formHeader = 'Create brand'
      onSaveText = 'Create brand'
      break
    case 'editBrandForm':
      formHeader = 'Edit brand'
      onSaveText = 'Update brand'
      break
    default:
      break
  }

  const handleCollapseInsider = () => setInsiderCollapsed(prev => !prev)

  return (
    <div className={style.form}>
      {brand && (
        <div className={style.formBrand}>
          <img src={brandCover} className={style.cover} alt={`${brand.brandName}'s cover`} />

          <div className={style.formBrand_details}>
            <img src={brandLogo} className={style.logo} alt={`${brand.brandName}'s logo`} />
            <h3 className={style.brandName}>{brand.brandName}</h3>
          </div>
        </div>
      )}

      <div className={style.signUp_form}>
        <h3>{formHeader}</h3>

        <div className={style.formLabel}>
          <p>BASIC (ALL FIELDS REQUIRED)</p>
        </div>

        <div className={style.uploadButtons}>
          <div className={style.uploadContainer}>
            {(previews.cover || brandCover) ? (
              <img
                className={cn(style.pic_preview, style.cover_preview)}
                src={previews.cover || brandCover}
                alt="cover"
              />
            ) : (
              <div className={cn(style.pic_preview, style.cover_preview)} />
            )}

            <Button className={style.filePickerButton} onClick={() => handleSyntheticClick(coverRef)}>
              Add brand cover photo
            </Button>

            <input
              hidden
              ref={coverRef}
              className={style.imageUpload}
              onChange={(e) => handlePicInput(e, setNewCover, "cover")}
              accept="image/*"
              type="file"
            />

            <p>{newCover?.name}</p>
          </div>

          <div className={style.uploadContainer}>
            {(previews.logo || brandLogo) ? (
              <img
                className={style.pic_preview}
                src={previews.logo || brandLogo}
                alt="logo"
              />
            ) : (
              <div className={style.pic_preview} />
            )}

            <Button className={style.filePickerButton} onClick={() => handleSyntheticClick(logoRef)}>
              Add brand logo
            </Button>

            <input
              hidden
              ref={logoRef}
              className={style.imageUpload}
              onChange={(e) => handlePicInput(e, setNewLogo, "logo")}
              accept="image/*"
              type="file"
            />

            <p>{newLogo?.name}</p>
          </div>
        </div>

        <div className={style.formInput}>
          {type === 'createBrandForm' && (
            <>
              <label htmlFor="brandId">{`Unique ID (Add country code, use camelCase, no spaces or special characters. Cannot start w/ number.)`}</label>
              <input
                name="brandId"
                type="text"
                placeholder="paulSmithUk"
                onChange={e => setUpdatedBrandValue('brandId', e.target.value)}
              />
            </>
          )}

          {type === 'createBrandForm' && (
            <>
              <label htmlFor="brandUrl">{`URL (https:// required)`}</label>
              <input
                name="brandUrl"
                type="text"
                placeholder="https://www.paulsmith.com/uk"
                value={brandUrl}
                onChange={e => setUpdatedBrandValue('brandUrl', e.target.value)}
              />
            </>
          )}

          <label htmlFor="brandRegion">Country</label>
          <select
            name="brandRegion"
            id="brandRegion"
            value={brandRegion}
            onChange={e => setUpdatedBrandValue('brandRegion', e.target.value)}
          >
            <option value="us">US</option>
            <option value="uk">UK</option>
          </select>

          <label htmlFor="saleType">Department</label>
          <div className={cn(style.departmentContainer, "dtRowCenter")}>

            <div className={cn(style.department, "dtRowCenter")}>
              <input
                type="checkbox"
                id="mens"
                name="mens"
                value="true"
                onChange={e => setUpdatedBrand(prevBrand =>
                ({
                  ...prevBrand,
                  brandDepartments: {
                    ...prevBrand.brandDepartments,
                    mens: !prevBrand.brandDepartments?.mens,
                  }
                }))}
              />
              <label htmlFor="mens">Men's</label><br />
            </div>

            <div className={cn(style.department, "dtRowCenter")}>
              <input
                type="checkbox"
                id="womens"
                name="womens"
                value="true"
                onChange={e => setUpdatedBrand(prevBrand =>
                ({
                  ...prevBrand,
                  brandDepartments: {
                    ...prevBrand.brandDepartments,
                    womens: !prevBrand.brandDepartments?.womens,
                  }
                }))}
              />
              <label htmlFor="womens">Women's</label><br />
            </div>
          </div>

          <label htmlFor="brandName">Display Name</label>
          <input
            name="brandName"
            type="text"
            placeholder="Paul Smith"
            value={brandName}
            onChange={e => setUpdatedBrandValue('brandName', e.target.value)}
          />

          <label htmlFor="brandDescription">Short Description (3-5 words)</label>
          <input
            name="brandDescription"
            type="text"
            placeholder="English designer..."
            value={brandDescription}
            onChange={e => setUpdatedBrandValue('brandDescription', e.target.value)}
          />
        </div>

        <div className={cn(style.formLabel, style.formLabelInsider)} onClick={handleCollapseInsider}>
          <p>INSIDER (OPTIONAL)</p>
          <img src={downTriangle} alt="arrow" className={cn(style.triangle, isInsiderCollapsed && style.insiderCollapsed)} />
        </div>

        <div className={cn(style.formInput, style.formInputInsider, isInsiderCollapsed && style.formInputInsiderCollapsed)}>
          <label htmlFor="url">Coming soon!</label>
        </div>
      </div>

      <Button onClick={handleUpdateBrand} className={style.button} isLoading={isLoading}>
        {onSaveText}
      </Button>
    </div>
  )
}