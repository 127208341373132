import React, { useContext } from 'react'
import cn from 'classnames'

import deleteSale from '../../actions/deleteSale'
import { formatDate } from '../../utils/helpers'

import style from "./style.module.css"
import WithLink from '../WithLink'
import { routes } from '../../utils/constants'

export default function BaseTile(props) {
  const {
    drop,
    setActiveSales,
    isAdminDashboard,
    isInactive,
    isEditForm,
    isBrandDashboard,
    isUserDashboard,
    gender,
    contentUrl,
    className,
    children,
    date,
    brand
  } = props

  const {
    brandId,
    brandUrl,
    brandLogo,
    brandName,
    brandRegion,
  } = brand

  // const start = formatDate(dropDate)

  const handleDeleteSale = async () => {
    // await deleteSale(brandId, saleId)
    // setActiveSales(prev => prev.filter(drop => drop.saleId !== saleId))
  }

  return (
    <div className={cn(
      style.container,
      className,
      isAdminDashboard && style.isAdminDashboard,
      isBrandDashboard && style.isBrandDashboard,
      isUserDashboard && style.isUserDashboard,
      isEditForm && style.isEditForm)}
    >
      <div className={style.baseTile}>
        <WithLink
          className={style.header}
          to={`${routes.ALL_BRANDS}/${brandId}`}
        >
          <div className={style.brand}>
            <img src={brandLogo} alt="brand logo" className={style.brandLogo} />
            <div>
              <h4>{brandName}</h4>

              <div className={style.date}>
                <p>RELEASED</p>
                <p>{date}</p>
              </div>
            </div>
          </div>
        </WithLink>

        <a
          href={contentUrl}
          className={style.contentContainer}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      </div >
    </div >
  )
}