import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Arrow from '../../assets/icons/arrow.svg'

import { UserContext, UserDispatchContext } from '../../providers/UserProvider'
import Page from '../../components/Page'
import BrandTile from '../../components/BrandTile'
import NextButton from '../../components/NextButton'
import style from "./style.module.css"
import updateBatchFollowBrands from '../../actions/updateBatchFollowBrands'
import { routes } from '../../utils/constants'

export default function OnboardFinish({ changePlan, updatePayment }) {
  const navigate = useNavigate()
  const user = useContext(UserContext)
  const setUser = useContext(UserDispatchContext)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  const {
    nextFollowing,
    nextMethod,
    nextPhone,
    nextAlertPreference
  } = user || {}

  const onSubmit = async () => {
    // if ((nextMethod === PREFERRED_METHODS.text.methodId
    //   || nextMethod === PREFERRED_METHODS.both.methodId) && !nextPhone) return setErrorMsg("Please enter a phone number if you'd like to receive text messages.")

    setIsLoading(true)

    await updateBatchFollowBrands({
      setUser,
      nextFollowing,
      nextMethod,
      nextPhone,
      nextAlertPreference,
      isOnboarded: true
    })

    setUser(prevUser => ({
      ...prevUser,
      userOnboarding: true,
      clientSecret: null,
      nextPhone: null,
      nextPlan: null,
      nextMethod: null,
    }))

    setIsLoading(false)
    navigate(routes.USER_DASHBOARD)
  }

  return (
    <Page noFooter>
      <section className={style.content}>
        <div className={style.backArrow} onClick={() => navigate(routes.ONBOARD_FOLLOW)}>
          <img
            src={Arrow}
            alt="arrow"
            className={style.arrow}
          />
        </div>

        <div className={style.container}>
          <h2>MY BRANDS</h2>

          <div className={style.signUp_brands}>
            {nextFollowing && Object.values(nextFollowing).map(brand => (
              <BrandTile
                brand={brand}
                key={brand.brandId}
                isStaticTile
              />
            ))}
          </div>
        </div>
      </section>

      <NextButton
        onClick={onSubmit}
        isActive
        isLoading={isLoading}
        buttonText="Confirm"
      />
    </Page>
  )
}