import React from 'react';
import { getFirestore } from 'firebase/firestore'
import { getAuth } from "firebase/auth"
import { getAnalytics } from "firebase/analytics"
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"

import { devFirebaseConfig, firebaseConfig, stagingFirebaseConfig } from './firebase/index.js';
import reportWebVitals from './reportWebVitals';

import { UserProvider } from './providers/UserProvider'
import { ModalProvider } from './providers/ModalProvider'
import { LocalizationProvider } from './providers/LocalizationProvider'
import { NotificationProvider } from './providers/NotificationProvider/index.jsx';
import { DirtyStateProvider } from './providers/DirtyStateProvider/index.jsx';
import App from './App.jsx';

import './index.module.css';
import './reset.css';

const config = window.location.hostname === "localhost"
  ? devFirebaseConfig
  : window.location.hostname === "snappi.vercel.app"
    ? devFirebaseConfig
    // ? stagingFirebaseConfig
    : firebaseConfig;

if (window.location.hostname === "snappi.vercel.app") console.log("Running on staging")
if (window.location.hostname === "localhost") console.log("Running on dev")

export const app = initializeApp(config)
export const db = getFirestore(app)
export const analytics = getAnalytics(app)
export const auth = getAuth()
export const storage = getStorage()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LocalizationProvider>
        <NotificationProvider>
          <UserProvider>
            <ModalProvider>
              <DirtyStateProvider>
                <App />
              </DirtyStateProvider>
            </ModalProvider>
          </UserProvider>
        </NotificationProvider>
      </LocalizationProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
