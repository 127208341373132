export const FOLLOW_LIMIT = 20;

export const COLLECTIONS = {
  USERS: 'users',
  BRANDS: 'brands',
  SALES: 'sales',
  FOLLOWING: 'following',
  FOLLOWERS: 'followers',
  LIKES: 'likes',
  COUPONS: 'coupons',
  DROPS: 'drops',
  ALL_DROPS: 'allDrops',
  ACTIVE_SALES: 'activeSales',
  ACTIVE_COUPONS: 'activeCoupons',
}

export const PREFERRED_METHODS = {
  text: {
    methodId: "text",
    methodName: "Text",
  },
  email: {
    methodId: "email",
    methodName: "Email",
  },
  both: {
    methodId: "both",
    methodName: "Text & Email",
  },
}

const ALL_BRANDS = '/brands'

export const routes = {
  LANDING: '/',
  EXPLORE: '/explore',
  ALL_BRANDS,
  BRAND_PROFILE_TEMPLATE: `${ALL_BRANDS}/:brandUid`,

  ONBOARD_FOLLOW: '/follow',
  ONBOARD_PLAN: '/plan',
  ONBOARD_ACCOUNT: '/account',
  ONBOARD_PICK: '/pick',
  ONBOARD_FINISH: '/finish',

  USER_DASHBOARD: '/dashboard',

  CHANGE_PLAN: '/change',
  CHANGE_PLAN_PAYMENT: '/updatepayment',
  USER_BILLING: '/billing',
  UPDATE_PAYMENT: '/updatefailedbill',

  ADMIN_DASHBOARD: '/admin',
}