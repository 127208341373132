import React from 'react'

import style from "./style.module.css"
import Button from '../Button'
import handleDeleteUserByAdmin from '../../actions/handleDeleteUserByAdmin'

export default function UserTile({ user }) {
  const {
    userEmail,
    userName,
    userId
  } = user

  const handleRemoveAll = async () => await handleDeleteUserByAdmin(userId)

  return (
    <div className={style.userTile}>
      <div className={style.userInfo}>
        <h4>{userName}</h4>
        <p>{userEmail}</p>
      </div>

      <Button onClick={handleRemoveAll}>
        DELETE
      </Button>
    </div>
  )
}