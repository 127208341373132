import {
  getFunctions,
  httpsCallable
} from "firebase/functions"
import cleanBrand from "../utils/cleanBrandES6.js"

export default async function updateFollowBrand({
  followBrand: followBrandRaw,
  unfollowBrandId,
}) {
  try {
    const followBrand = followBrandRaw && cleanBrand(followBrandRaw)

    const functions = getFunctions()
    const updateFollowBrand = httpsCallable(functions, "updateFollowBrand")

    const res = await updateFollowBrand({
      ...(followBrand && { followBrand }),
      ...(unfollowBrandId && { unfollowBrandId }),
    })

    return res.data
  } catch (error) {
    console.log("Error following brands:", error)
  }
}