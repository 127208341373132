import {
  getFunctions,
  httpsCallable
} from "firebase/functions";

export default async function handleUpdateAccount(data) {
  try {
    const functions = getFunctions()
    const updateAccount = httpsCallable(functions, "updateAccount")

    const res = await updateAccount(data)
    return res.data
  } catch (error) {
    console.log("Error updating account:", error)
  }
}