import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'

import Arrow from '../../assets/icons/arrow.svg'

import { UserContext, UserDispatchContext } from '../../providers/UserProvider'
import Page from '../../components/Page'
import BrandTile from '../../components/BrandTile'
import NextButton from '../../components/NextButton'
import style from "./style.module.css"
import { routes } from '../../utils/constants'

export default function OnboardPick() {
  const navigate = useNavigate()
  const user = useContext(UserContext)
  const setUser = useContext(UserDispatchContext)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  const {
    nextAlertPreference
  } = user || {}

  const {
    sales,
    drops
  } = nextAlertPreference || {}

  const setSelected = (alertType) => {
    setUser(prevUser => ({
      ...prevUser,
      nextAlertPreference: {
        ...prevUser.nextAlertPreference,
        [alertType]: !prevUser.nextAlertPreference?.[alertType]
      }
    }))
  }

  const onSubmit = async () => {
    if (!sales && !drops) return setErrorMsg("Please select at least one alert type.")
    navigate(routes.ONBOARD_FINISH)
  }

  return (
    <Page noFooter>
      <section className={style.content}>
        <div className={style.backArrow} onClick={() => navigate(routes.ONBOARD_FOLLOW)}>
          <img
            src={Arrow}
            alt="arrow"
            className={style.arrow}
          />
        </div>

        <div className={style.container}>
          <div className={style.header}>
            <h2>PICK ALERTS</h2>
            <p>Select the alert type you want to receive from brands</p>
          </div>

          <div className={style.options}>
            <div className={cn(style.optionContainer, style.saleOption, sales && style.isSelected)} onClick={() => setSelected("sales")}>
              <h2>Sales</h2>
              <p>Receive emails when brands you follow go on sale.</p>
            </div>

            <div className={cn(style.optionContainer, style.dropOption, drops && style.isSelected)} onClick={() => setSelected("drops")}>
              <h2>Drops</h2>
              <p>Receive emails when brands you follow release a new product or collection.</p>
            </div>
          </div>

          {errorMsg && <p className={style.error}>{errorMsg}</p>}
        </div>
      </section>

      <NextButton
        onClick={onSubmit}
        isActive
        isLoading={isLoading}
        buttonText="Finish"
      />
    </Page>
  )
}