import React from 'react'

import Page from '../../components/Page'
import SignUpForm from '../../components/SignUpForm'

import style from "./style.module.css"

export default function OnboardAccount() {
  return (
    <Page noFooter contentClassName={style.content}>
      <div className={style.intro}>
        <h2>
          Get Snappi
        </h2>
      </div>

      <form className={style.signUp_form_container}>
        <SignUpForm />
      </form>
    </Page>
  )
}