import React, { useContext, useEffect } from 'react'
import cn from 'classnames'
import Footer from '../Footer'
import style from "./style.module.css"
import { ModalContext } from '../../providers/ModalProvider'

export default function Page({ children, pageClassName, contentClassName, noFooter }) {
  const { isOpen } = useContext(ModalContext)

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'unset'

    return () => document.body.style.overflow = 'unset'
  }, [isOpen])

  return (
    <div className={cn(style.page, pageClassName, isOpen && style.preventScroll)}>
      <div className={cn(style.content, contentClassName, noFooter && style.noFooter)}>
        {children}
      </div>

      <Footer noFooter={noFooter} />
    </div>
  )
}