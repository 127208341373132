import {
  doc,
  setDoc
} from "firebase/firestore"
import {
  db
} from ".."
import { COLLECTIONS } from "../utils/constants"

// WRITE TO BRAND MUST BE PROTECTED BY FB RULE
const createBrand = async (brand) => {
  try {
    const brandRef = doc(db, COLLECTIONS.BRANDS, brand.brandId)
    await setDoc(brandRef, brand)
  } catch (error) {
    console.log("Error creating brand:", error)
  }
}

export default createBrand