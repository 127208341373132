import {
  collection,
  addDoc,
  setDoc,
  doc
} from "firebase/firestore"
import {
  db
} from ".."
import { COLLECTIONS } from "../utils/constants"
import {
  convertDateToUnixOfCountry
} from "../utils/helpers"

export default async function createCoupon(brandId, coupon, brandRegion) {
  try {
    const unixStartDate = convertDateToUnixOfCountry(coupon.couponStartDate, brandRegion, false)
    coupon.couponStartDate = unixStartDate

    if (coupon.couponEndDate) {
      const unixEndDate = convertDateToUnixOfCountry(coupon.couponEndDate, brandRegion, true)
      coupon.couponEndDate = unixEndDate
    } else {
      delete coupon.couponEndDate
    }

    const activeCouponRef = collection(db, COLLECTIONS.ACTIVE_COUPONS)
    const newCoupon = await addDoc(activeCouponRef, coupon)

    const brandRef = doc(db, COLLECTIONS.BRANDS, brandId, COLLECTIONS.COUPONS, newCoupon.id)
    const res = await setDoc(brandRef, coupon)
    console.log("res", res)
  } catch (error) {
    console.log("Error creating coupon:", error)
  }
}