import React from 'react'
import cn from 'classnames'

import WithLink from '../WithLink'
import FollowButton from '../FollowButton'

import style from "./style.module.css"
import { routes } from '../../utils/constants'

export default function BrandTile(props) {
  const { brand,
    isSelected,
    isSelectableTile,
    onClick,
    isStaticTile
  } = props

  const { brandName,
    brandDescription,
    name,
    brandLogo,
    brandCover
  } = brand || {}

  return (
    <WithLink
      to={`${routes.ALL_BRANDS}/${brand.brandId}`}
      className={cn(
        style.tile_container,
        isSelected && style.isSelected,
        isStaticTile && style.staticTile,
        (isSelectableTile || isStaticTile) && style.disableFollowButton,
      )}
      disableLink={(isStaticTile || isSelectableTile)}
      {...onClick && { onClick: () => onClick?.(name) }}
    >
      <img
        src={brandCover}
        className={cn(style.brandCover)}
        onClick={() => onClick?.(name)}
        alt={`${brandName}'s cover`}
      />

      <div className={style.tileDetails}>
        <div className={style.tileDetails_main}>
          <img src={brandLogo} className={style.brandLogo} alt="Profile" />
          <h4>{brandName}</h4>
        </div>

        <p className={style.brandDescription}>{brandDescription}</p>

        <FollowButton className={style.followButton} brand={brand} />
      </div>
    </WithLink>
  )
}