import React, { createContext, useState } from "react"

// NotificationContext: to query the context state
// NotificationDispatchContext: to mutate the context state
const NotificationContext = createContext(undefined)
const NotificationDispatchContext = createContext(undefined)

function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState({
    type: '',
    message: ''
  })

  return (
    <NotificationContext.Provider value={notifications}>
      <NotificationDispatchContext.Provider value={setNotifications}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationContext.Provider>
  )
}

export { NotificationProvider, NotificationContext, NotificationDispatchContext }