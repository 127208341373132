import {
  collection,
  query,
  getDocs
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'

export default async function getAllBrands() {
  const allBrands = {}

  const q = query(collection(db, COLLECTIONS.BRANDS))
  const querySnapshot = await getDocs(q)

  querySnapshot.forEach((doc) => allBrands[doc.id] = doc.data())

  return allBrands
}