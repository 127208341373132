import React, { useContext, useState } from 'react'
import cn from 'classnames'

import { formatDate, getUrl } from '../../utils/helpers'

import style from "./style.module.css"
import { UserContext } from '../../providers/UserProvider'
import DropContent from './DropContent'
import SaleContent from './SaleContent'
import { Link } from 'react-router-dom'

export default function ContentCard(props) {
  const user = useContext(UserContext)
  const {
    drop,
    sale,
    className
  } = props

  const content = drop || sale

  const {
    brandName,
    brandUrl,
    brandLogo,
    brandId,
    dropUrl,
    saleUrl
  } = content || {}

  const {
    userGender,
  } = user

  const url = getUrl(dropUrl || saleUrl, userGender, brandUrl)

  return (
    <Link to={`/brands/${brandId}`} className={cn(style.card, "columnTopLeft", className)}>
      <div className={cn(style.brandInfo, "dtRowCenter")}>
        <img className={style.brandLogo} src={brandLogo} alt="brand logo" />
        <h4 className={style.brandName}>{brandName}</h4>
      </div>

      {drop ? <DropContent drop={drop} /> : <SaleContent sale={sale} />}
    </Link>
  )
}