import React, { useContext, useState, useEffect } from 'react'
import cn from 'classnames'

import { UserContext } from '../../providers/UserProvider'
import Page from '../../components/Page'

import loading from '../../assets/icons/loadingBlack.svg'
import style from "./style.module.css"
import BrandNugget from '../../components/BrandNugget'
import getMyActiveSales from '../../actions/getMyActiveSales'
import SaleTile from '../../components/SaleTile'
import getMyActiveCoupons from '../../actions/getMyActiveCoupons'
import CouponTile from '../../components/CouponTile'
import FeedTabs from '../../components/FeedTabs'
import DropTile from '../../components/DropTile'
import getMyRecentDrops from '../../actions/getMyRecentDrops'

export default function UserDashboard() {
  const user = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [myActiveSales, setMyActiveSales] = useState([])
  const [myActiveCoupons, setMyActiveCoupons] = useState([])
  const [activeTab, setActiveTab] = useState("all")
  const [drops, setDrops] = useState([])

  const {
    userName,
    following: followingObject,
    userId,
    userRegion,
    userGender,
    pastUserLastLogin
  } = user

  const following = followingObject && Object.values(followingObject)

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    if (!userId) return
    setIsLoading(false)
    const brandIds = following.map(brand => brand.brandId)

    const handleGetMyActiveSales = async () => {
      const sales = await getMyActiveSales(brandIds)
      setMyActiveSales(sales)
    }

    const handleGetMyActiveCoupons = async () => {
      const coupons = await getMyActiveCoupons(brandIds)
      setMyActiveCoupons(coupons)
    }

    const handleGetMyRecentDrops = async () => {
      const coupons = await getMyRecentDrops(brandIds, pastUserLastLogin)
      setDrops(coupons)
    }

    handleGetMyActiveSales()
    handleGetMyActiveCoupons()
    handleGetMyRecentDrops()
  }, [userId])

  return (
    <Page contentClassName={style.page}>
      <div className={style.dashboard_left}>
        <section className={cn(style.widget, style.user)}>
          <div className={cn(style.widget_section_left_justify, style.name)}>
            {!isLoading && (
              <>
                <h1>{userName}</h1>

                <div className={style.user_row}>
                  <p>Department</p><span>{userGender}</span>
                </div>
                <div className={style.user_row}>
                  <p>Country</p> <span>{userRegion}</span>
                </div>
              </>
            )}

            {isLoading && (
              <img
                src={loading}
                alt="loading"
                className={style.loading}
              />
            )}
          </div>
        </section>

        <section className={cn(style.widget, style.following)}>
          <div className={style.widget_title}>
            <p>{`${following?.length} Brands Following`}</p>
          </div>

          <div className={style.widget_section_center_justify}>
            {following?.length > 0 && following?.map((brand) => (
              <div className={style.brandNuggetWrapper} key={brand.brandId}>
                <BrandNugget brand={brand} isSelected />
              </div>
            ))}

            {following?.length === 0 && (
              <img
                src={loading}
                alt="loading"
                className={style.loading}
              />
            )}
          </div>
        </section>
      </div>

      <section className={style.dashboard_right}>
        <section className={cn(style.widget, style.fullWidth)}>
          <FeedTabs activeTab={activeTab} setActiveTab={setActiveTab} country={userRegion} />
        </section>

        {((activeTab === "all" && myActiveCoupons.length > 0) || activeTab === "coupons") && (
          <section className={cn(style.widget, style.fullWidth, style.container)}>
            <div className={style.widget_title}>
              <p>{`Active ${userRegion === "uk" ? "Promo Codes" : "Coupons"}`}</p>
            </div>

            {!isLoading && (
              <div className={cn(style.coupon_container)}>
                {myActiveCoupons.map((coupon) => (
                  <CouponTile
                    coupon={coupon}
                    key={`${coupon.couponStartDate}-${coupon.couponDescription}`}
                    isUserDashboard
                  />
                ))}
              </div>
            )}

            {isLoading && (
              <img
                src={loading}
                alt="loading"
                className={style.loading}
              />
            )}
          </section>
        )}

        {(activeTab === "all" || activeTab === "sales") && (
          <section className={cn(style.widget, style.fullWidth)}>
            <div className={style.widget_title}>
              <p>Active Sales</p>
            </div>

            {!isLoading && (
              <div className={cn(style.sales_container)}>
                {myActiveSales.map((s, i) => (
                  <SaleTile
                    sale={s}
                    isUserDashboard
                    key={`${s.saleStartDate}-${s.saleHeader}`}
                    isFirst={i === 0}
                  />
                ))}

                {myActiveSales.length === 0 && (
                  <div className={style.emptySales}>
                    NO PAST SALES
                  </div>
                )}
              </div>
            )}

            {isLoading && (
              <img
                src={loading}
                alt="loading"
                className={style.loading}
              />
            )}
          </section>
        )}

        {(activeTab === "all" || activeTab === "drops") && (
          <section className={cn(style.widget, style.fullWidth)}>
            <div className={style.widget_title}>
              <p>Recent Drops</p>
            </div>

            {!isLoading && (
              <div className={cn(style.sales_container)}>
                {drops.map((d, i) => (
                  <DropTile
                    drop={d}
                    key={d.dropDate}
                    isBrandDashboard
                    isFirst={i === 0}
                  />
                ))}

                {drops.length === 0 && (
                  <div className={style.emptySales}>
                    NO RECENT DROPS
                  </div>
                )}
              </div>
            )}

            {isLoading && (
              <img
                src={loading}
                alt="loading"
                className={style.loading}
              />
            )}
          </section>
        )}
      </section>
    </Page>
  )
}