import React, { useContext, useEffect, useState } from 'react'
import cn from 'classnames'

import Button from '../Button'
import { ModalDispatchContext } from '../../providers/ModalProvider'
import { formatDate, getTodaysDate } from '../../utils/helpers'
import getCoupons from '../../actions/getCoupons'

import style from "./style.module.css"
import createCoupon from '../../actions/createCoupon'
import deactivateCoupon from '../../actions/deactivateCoupon'

export default function UpdateCouponsForm({ brand }) {
  const [isLoading, setIsLoading] = useState(false)
  const [isCreateNew, setIsCreateNew] = useState(false)
  const [nextCoupon, setNextCoupon] = useState({
    couponStartDate: getTodaysDate(),
    couponIsActive: true,
    brandId: brand.brandId,
    couponType: "welcome"
  })
  const [activeCoupons, setActiveDiscountCodes] = useState([])
  const [inactiveCoupons, setInactiveDiscountCodes] = useState([])

  const {
    brandLogo,
    brandCover,
    brandId,
    brandName,
    brandRegion,
    brandUrl
  } = brand || {}

  const setModal = useContext(ModalDispatchContext)

  useEffect(() => {
    const handleGetCodes = async (brandId) => {
      const { activeCoupons, inactiveCoupons } = await getCoupons(brandId)
      setActiveDiscountCodes(activeCoupons)
      setInactiveDiscountCodes(inactiveCoupons)
    }

    handleGetCodes(brandId)
  }, [])

  const handleCreateDiscountCode = async () => {
    if (!nextCoupon.couponPercent) return alert('Please fill in the discount percent')
    if (!nextCoupon.couponCode) return alert('Please fill in the code')

    nextCoupon.brandLogo = brandLogo
    nextCoupon.brandName = brandName
    nextCoupon.brandUrl = brandUrl
    nextCoupon.brandRegion = brandRegion
    nextCoupon.couponPercent = Number(nextCoupon.couponPercent)

    setIsLoading(true)
    await createCoupon(brandId, nextCoupon, brandRegion)
    setModal({ isOpen: false })
    setIsLoading(false)
    setIsCreateNew(false)
    setActiveDiscountCodes((prev) => ([...prev, nextCoupon]))
  }

  const handleDeactivateCode = (coupon) => {
    const nextCode = deactivateCoupon(coupon, brandRegion)
    const nextCodes = activeCoupons.filter((coupon) => coupon.couponId !== coupon.couponId)
    setActiveDiscountCodes(nextCodes)
    setInactiveDiscountCodes((prev) => ([...prev, nextCode]))
  }

  return (
    <div className={style.form}>
      {brand && (
        <div className={style.formBrand}>
          <img src={brandCover} className={style.cover} alt={`${brandName}'s cover`} />

          <div className={style.formBrand_details}>
            <img src={brandLogo} className={style.logo} alt={`${brandName}'s logo`} />
            <h3 className={style.brandName}>{brandName}</h3>
          </div>
        </div>
      )}

      <div className={style.signUp_form}>
        {/* <h3>Update Discount Codes</h3> */}

        <label>
          Active Discount Codes
        </label>

        {activeCoupons.map((coupon) => {
          const { couponCode, couponDescription, couponStartDate } = coupon
          return (
            <div className={style.existingCode} key={`${couponStartDate}-${couponCode}`}>
              <p className={cn(style.codeSection, style.couponCode)}>{couponCode}</p>
              <p className={cn(style.codeSection, style.couponDescription)}>{couponDescription}</p>
              <div className={cn(style.codeSection, style.deactivate)} onClick={() => handleDeactivateCode(coupon)}>
                Deactivate
              </div>
            </div>
          )
        })}

        {!activeCoupons.length && (
          <div className={style.existingCode}>
            <p className={style.emptyMessage}>No active discount codes</p>
          </div>
        )}

        <label>
          Inactive Discount Codes
        </label>

        {inactiveCoupons.map((coupon) => {
          const { couponCode, couponDescription, couponStartDate, couponEndDate } = coupon
          return (
            <div className={style.existingCode} key={`${couponStartDate}-${couponCode}`}>
              <p className={cn(style.codeSection, style.couponCode)}>{couponCode}</p>
              <p className={cn(style.codeSection, style.couponDescription)}>{couponDescription}</p>
              <div className={cn(style.codeSection, style.dateRange)}>
                {`${formatDate(couponStartDate)} - ${formatDate(couponEndDate)}`}
              </div>
            </div>
          )
        })}

        {!inactiveCoupons.length && (
          <div className={style.existingCode}>
            <p className={style.emptyMessage}>No inactive discount codes</p>
          </div>
        )}

        {isCreateNew && (
          <>
            <h5>
              New Discount Code
            </h5>
            <label htmlFor="couponPercent">Coupon percent*</label>
            <input
              name="couponPercent"
              type="number"
              placeholder='Enter percent...'
              className={style.codeForm}
              onChange={e => setNextCoupon((prev) => (({
                ...prev,
                couponPercent: e.target.value,
              })))}
            />

            <label htmlFor="couponCode">Code*</label>
            <input
              name="couponCode"
              type="text"
              placeholder='Enter code...'
              className={style.codeForm}
              onChange={e => setNextCoupon((prev) => (({
                ...prev,
                couponCode: e.target.value,
              })))}
            />

            <label htmlFor="couponDescription">Description</label>
            <input
              name="couponDescription"
              type="text"
              placeholder='Enter description...'
              className={style.descriptionForm}
              onChange={e => setNextCoupon((prev) => (({
                ...prev,
                couponDescription: e.target.value,
              })))}
            />

            <label htmlFor="couponType">Type</label>
            <select
              name="couponType"
              id="couponType"
              value={nextCoupon.couponType}
              onChange={e => setNextCoupon((prev) => (({
                ...prev,
                couponType: e.target.value,
              })))}
            >
              <option value="welcome">Welcome Code</option>
              <option value="member">Member Code</option>
              <option value="general">General Sale Code</option>
              <option value="flash">Flash Sale Code</option>
              <option value="seasonal">Seasonal Sale Code</option>
            </select>
          </>
        )}
      </div>

      {isCreateNew ? (
        <Button
          onClick={handleCreateDiscountCode}
          className={style.button}
          isLoading={isLoading}
        >
          Create coupon
        </Button>
      ) : (
        <Button onClick={() => setIsCreateNew(true)}>
          Add new coupon
        </Button>
      )}
    </div>
  )
}