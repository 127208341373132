import {
  collection,
  query,
  getDocs,
  orderBy,
  where
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS } from '../utils/constants'

export default async function getMostRecentSales() {
  const activeSales = []

  const now = new Date()
  const fifteenDaysAgoDate = new Date(now.getTime() - (15 * 24 * 60 * 60 * 1000))
  const fifteenDaysAgo = Math.floor(fifteenDaysAgoDate.getTime())

  const q = query(
    collection(db, COLLECTIONS.ACTIVE_SALES),
    orderBy("saleStartDate", "desc"),
    where("saleStartDate", ">", fifteenDaysAgo),
    where("saleIsActive", "==", true),
  )
  const querySnapshot = await getDocs(q)

  querySnapshot.forEach((doc) => {
    const data = doc.data()
    data.saleId = doc.id
    activeSales.push(data)
  })

  return activeSales
}