import React from 'react'
import cn from 'classnames'

import WithLink from '../WithLink'

import style from "./style.module.css"
import { routes } from '../../utils/constants'

export default function BrandNugget({ brand, onClick, isSelected, disableLink }) {
  const {
    brandId,
    brandName,
    brandLogo,
  } = brand || {}
  return (
    <div className={style.brand_nugget_container}>
      <WithLink
        className={cn(style.brand_nugget, isSelected && style.selected)}
        to={!disableLink && `${routes.ALL_BRANDS}/${brandId}`}
        {...onClick && { onClick: () => onClick(brand) }}
      >
        <img src={brandLogo} alt={brandId} className={style.brand_nugget_image} />
        <p>{brandName}</p>
      </WithLink>
    </div>
  )
}