import {
  doc,
  getDoc
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'

const getBrand = async (brandId) => {
  const docRef = doc(db, COLLECTIONS.BRANDS, brandId)
  const docSnap = await getDoc(docRef)

  if (!docSnap.exists()) return {}

  return docSnap.data() || {}
}

export default getBrand