import React from 'react'
import cn from 'classnames'

import Arrow from '../../assets/icons/arrow.svg'
import loading from '../../assets/icons/loadingWhite.svg'
import style from "./style.module.css"

// CANT MOVE LOADING FLAG IN HERE BC ONE FUNCTION DOESNT WORK
export default function NextButton({ onClick, isActive, buttonText, isLoading }) {
  const handleOnClick = async () => !isLoading && await onClick()
  return (
    <div
      className={cn(style.next_button_container, isActive && style.visible)}
      onClick={handleOnClick}
    >
      <h4 className={style.nextButtonText}>{buttonText}</h4>

      {isLoading ? (
        <img
          src={loading}
          alt="loading"
          className={style.loading}
        />
      ) : (
        <img
          src={Arrow}
          alt="arrow"
          className={style.arrow}
        />
      )}
    </div>
  )
}