import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { sendPasswordResetEmail } from "firebase/auth";
import cn from 'classnames'

import handleUpdateAccount from '../../actions/handleUpdateAccount'
import handleBillingDashboardLink from '../../actions/handleBillingDashboardLink'
import { isValidPhoneNumber } from '../../utils/helpers'

import { UserContext, UserDispatchContext } from '../../providers/UserProvider'
import { NotificationDispatchContext } from "../../providers/NotificationProvider"
import { useDirtyState } from '../../providers/DirtyStateProvider'
import Page from '../../components/Page'
import Button from '../../components/Button'
import loading from '../../assets/icons/loadingWhite.svg'

import style from "./style.module.css"
import { auth } from '../..';
import DropDownMenu from '../../components/DropDownMenu';

export default function Settings() {
  const setNotifications = useContext(NotificationDispatchContext)
  const { isDirty } = useDirtyState()
  const user = useContext(UserContext)
  const setUser = useContext(UserDispatchContext)
  const { setDirtyState } = useDirtyState()
  const [editedUser, setEditedUser] = useState({})
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [isBillingLoading, setIsBillingLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  const {
    userPhone,
    userName,
    userEmail,
    userGender,
    userAlertPreference,
    nextMethod,
    nextPhone,
    userRegion,
  } = user || {}

  const {
    nextAlertPreference
  } = editedUser

  const setUserValue = (name, value) => {
    setEditedUser(prev => ({ ...prev, [name]: value }))
    setDirtyState(true)
  }

  const handleStripeBillingDashboard = async () => {
    setIsBillingLoading(true)
    const link = await handleBillingDashboardLink()
    window.open(link, '_blank').focus()
    setIsBillingLoading(false)
  }

  const handleSaveAccount = async () => {
    // if (nextPhone && !isValidPhoneNumber(nextPhone)) return setErrorMsg('Not valid userPhone')
    // if (!userPhone && !nextPhone && (
    //   nextMethod === PREFERRED_METHODS.text.methodId
    //   || nextMethod === PREFERRED_METHODS.both.methodId
    // )) return setErrorMsg("Please enter a userPhone number if you'd like to receive text messages")
    setIsSaveLoading(true)

    const updatedUser = {
      ...(nextPhone) && { userPhone: nextPhone },
      ...(nextMethod) && { userMethod: nextMethod },
      ...(editedUser.userName) && { userName: editedUser.userName },
      ...(editedUser.userRegion) && { userRegion: editedUser.userRegion },
      ...(editedUser.userGender) && { userGender: editedUser.userGender },
      ...(editedUser.nextAlertPreference) && { userAlertPreference: editedUser.nextAlertPreference },
    }

    await handleUpdateAccount(updatedUser)

    setUser(prevUser => ({
      ...prevUser,
      ...updatedUser,
      nextPhone: null,
      nextMethod: null
    }))

    setEditedUser({})
    setDirtyState(false)
    setIsSaveLoading(false)
    setErrorMsg("")
    // TODO: SHOW SAVED ANIMATION
  }

  const handleResetPassword = async () => {
    try {
      const notification = {
        type: 'success',
        message: 'Password reset email sent',
        fadeout: true,
        isActive: true,
      }

      await sendPasswordResetEmail(auth, userEmail)

      setNotifications(notification)
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
    }
  }

  const alertToUse = nextAlertPreference || userAlertPreference

  return (
    <Page contentClassName={"columnStartCenter"}>
      <div className={style.content}>
        <div className={style.intro}>
          <h2>
            Settings
          </h2>
        </div>

        <section className={style.section}>
          <h4>PREFERENCES & INFO</h4>
          <div className={style.profile_info}>
            <div className={style.profile_info_inputs}>
              <div className={style.inputContainer}>
                <label>FULL NAME</label>
                <input
                  name="userName"
                  type="text"
                  placeholder="Joe"
                  defaultValue={userName}
                  onChange={e => setUserValue('userName', e.target.value)}
                />
              </div>

              <div className={style.inputContainer}>
                <label>DEPARTMENT</label>
                <DropDownMenu
                  className={cn(style.genderSelect)}
                  keyName="userGender"
                  keyDisplayName="department"
                  onClick={(key, value) => setUserValue(key, value)}
                  selectedValue={editedUser.userGender || userGender}
                  options={[{
                    name: "Women's",
                    value: "womens",
                  },
                  {
                    name: "Men's",
                    value: "mens",
                  },
                  {
                    name: "Both",
                    value: "general",
                  }]}
                />
              </div>

              <div className={style.inputContainer}>
                <label>COUNTRY</label>
                <DropDownMenu
                  className={cn(style.genderSelect)}
                  keyName="userRegion"
                  keyDisplayName="country"
                  onClick={(key, value) => setUserValue(key, value)}
                  selectedValue={editedUser.userRegion || userRegion}
                  options={[{
                    name: "US",
                    value: "us",
                  },
                  {
                    name: "UK",
                    value: "uk",
                  }]}
                />
              </div>

              <div className={style.inputContainer}>
                <label>ALERT PREFERENCE</label>
                <DropDownMenu
                  keyName="userAlertPreference"
                  keyDisplayName="alert type"
                  onClick={(key, value) => {
                    setDirtyState(true)

                    if (value === "both") {
                      setEditedUser(prevUser => ({
                        ...prevUser,
                        nextAlertPreference: {
                          sales: true,
                          drops: true
                        }
                      }))
                    } else {
                      setEditedUser(prevUser => ({
                        ...prevUser,
                        nextAlertPreference: {
                          [value]: true
                        }
                      }))
                    }
                  }}
                  selectedValue={!!alertToUse?.sales && !!alertToUse?.drops ? "both" : !!alertToUse?.sales ? "sales" : "drops"}
                  options={[{
                    name: "Sales",
                    value: "sales",
                  },
                  {
                    name: "Drops",
                    value: "drops",
                  },
                  {
                    name: "Both",
                    value: "both",
                  }]}
                />
              </div>
            </div>

            {(isDirty || errorMsg) && (
              <div className={style.intro}>
                {isDirty && (
                  <Button onClick={handleSaveAccount} isLoading={isSaveLoading}>
                    Save
                  </Button>
                )}

                {errorMsg && (
                  <p className={style.errorMsg}>
                    {errorMsg}
                  </p>
                )}
              </div>
            )}
          </div>
        </section>

        {/* <section className={style.section}>
          <h4>UPDATE SUBSCRIPTION & BILLING INFO</h4>

          <div className={style.subscriptionOptions}>
            <Link to={routes.CHANGE_PLAN} className={cn(style.option, style.optionOne)}>
              <h3>
                Change Subscription
              </h3>
            </Link>

            <div className={cn(style.option, style.optionTwo)} onClick={handleStripeBillingDashboard}>
              <h3>
                Billing Dashboard
              </h3>
              {isBillingLoading && (
                <img
                  src={loading}
                  className={style.billingLoading}
                  alt="loading"
                />
              )}
              <h3>
                Edit payment userMethod
                <br />
                Edit billing information
                <br />
                View invoice history
                <br />
                Cancel subscription
              </h3>
            </div>

            <Button onClick={() => handleModal({ type: 'cancelSubscription', functions: { confirmCancelSubscription } })}>
              Cancel Subscription
            </Button>
          </div>
        </section> */}

        <div className={style.section}>
          <div
            onClick={handleResetPassword}
            className={cn(style.option, style.reset)}
          >
            <h3>
              Reset password
            </h3>
          </div>
        </div>
      </div>
    </Page>
  )
}