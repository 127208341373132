import React, { useContext, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'

import { UserContext, UserDispatchContext } from '../../providers/UserProvider'
import { auth } from '../..'
import createUser from '../../actions/createUser'

import downarrow from '../../assets/icons/downTriangle.svg'

import style from "./style.module.css"
import Button from '../Button'
import getMe from '../../actions/getMe'
import { routes } from '../../utils/constants'
import DropDownMenu from '../DropDownMenu'

export default function SignUpForm(props) {
  const user = useContext(UserContext)
  const setUser = useContext(UserDispatchContext)
  const navigate = useNavigate()

  const [password, setPassword] = useState()
  const [errorMsg, setErrorMsg] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const {
    isSignIn,
    isModal,
    setModal
  } = props

  const {
    userEmail,
    userName,
    userRegion,
    userGender
  } = user

  const setUserValue = (name, value) => {
    setUser(prevUser => ({
      ...prevUser,
      [name]: value,
    }))
  }

  const signUp = async () => {
    try {
      if (!userEmail) return setErrorMsg('Email required')
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(userEmail)) return setErrorMsg('Invalid email address')
      if (!password) return setErrorMsg('Password required')
      if (!userName) return setErrorMsg('Full name required')
      if (!userRegion) return setErrorMsg('Country required')
      if (!userGender) return setErrorMsg('Department required')

      setIsLoading(true)

      const userCredential = await createUserWithEmailAndPassword(auth, userEmail, password)
      const firebaseUser = userCredential.user
      const { uid: userId } = firebaseUser

      setUser((prevUser) => ({
        ...prevUser,
        userId
      }))

      const newUser = {
        userEmail,
        userName,
        userId,
        userRegion,
        userGender,
        userOnboarding: true
      }

      await createUser(newUser)

      setErrorMsg()
      setModal?.({})
      setIsLoading(false)
      navigate(routes.ONBOARD_FOLLOW)
    } catch (error) {
      setErrorMsg('Error signing up')
      console.log(`Error signing up: `, error)
    }
  }

  const signIn = async () => {
    try {
      if (!userEmail) return setErrorMsg('We need your email 🙂')
      if (!password) return setErrorMsg('We need a password 🙂')

      setIsLoading(true)

      const userCredential = await signInWithEmailAndPassword(auth, userEmail, password)
      const user = userCredential.user
      const { uid: userId } = user

      setUser(prevUser => ({
        ...prevUser,
        userId
      }))

      await getMe(userId)

      setErrorMsg()
      setModal({})
      setIsLoading(false)
      navigate(routes.USER_DASHBOARD)
    } catch (error) {
      setErrorMsg('Error sigining in')
      console.log('Error sigining in', error)
    }
  }

  const buttonText = isSignIn ? 'Sign In' : 'Continue'

  const handleSignUpIn = (e) => {
    e.preventDefault()
    if (isSignIn) signIn()
    else signUp()
  }

  return (
    <>
      <div className={style.signUp_form}>
        {!isSignIn && (
          <>
            <label htmlFor="userName">Full name</label>
            <input
              name="userName"
              type="text"
              placeholder="Coco Chanel"
              onChange={e => setUserValue('userName', e.target.value)}
            />
          </>
        )}

        <label htmlFor="userEmail">Email</label>
        <input
          name="userEmail"
          type="email"
          placeholder="coco@gmail.com"
          defaultValue={userEmail}
          onChange={e => setUserValue('userEmail', e.target.value)}
        />

        <label htmlFor="password">{`Password${!isSignIn ? " (Minimum 6 characters)" : ""}`}</label>
        <input
          name="password"
          type="password"
          placeholder="Password"
          onChange={e => setPassword(e.target.value)}
        />

        {!isSignIn && (
          <>
            <label htmlFor="userRegion">Department</label>
            <DropDownMenu
              className={cn(style.genderSelect)}
              keyName="userGender"
              keyDisplayName="department"
              onClick={(key, value) => setUserValue(key, value)}
              selectedValue={userGender}
              options={[{
                name: "Women's",
                value: "womens",
              },
              {
                name: "Men's",
                value: "mens",
              },
              {
                name: "Both",
                value: "general",
              }]}
            />
          </>
        )}

        {!isSignIn && (
          <>
            <label htmlFor="userRegion">Country</label>
            <DropDownMenu
              keyName="userRegion"
              keyDisplayName="country"
              onClick={(key, value) => setUserValue(key, value)}
              selectedValue={userRegion}
              options={[{
                name: "US",
                value: "us",
              },
              {
                name: "UK",
                value: "uk",
              }]}
            />
          </>
        )}

        {errorMsg && <p className={style.errorMsg}>{errorMsg}</p>}
      </div>

      <div className={cn(style.buttonContainer, isModal && style.modal)}>
        <Button onClick={handleSignUpIn} isLoading={isLoading}>
          {buttonText}
        </Button>
      </div>
    </>
  )
}