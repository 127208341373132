import {
  doc,
  deleteDoc
} from "firebase/firestore"
import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'

export default async function deleteSale(brandId, saleId, saleIsActive) {
  try {
    let activeSaleRes
    const promises = []

    if (saleIsActive) {
      const activeSaleRef = doc(db, COLLECTIONS.ACTIVE_SALES, saleId)
      activeSaleRes = deleteDoc(activeSaleRef)
    }

    const saleRef = doc(db, COLLECTIONS.BRANDS, brandId, COLLECTIONS.SALES, saleId)
    const saleRes = deleteDoc(saleRef)

    promises.push(activeSaleRes, saleRes)
    const res = await Promise.all(promises)

    return res
  } catch (error) {
    console.log(error)
  }
}