import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'

import { NotificationDispatchContext, NotificationContext } from '../../providers/NotificationProvider'
import style from "./style.module.css"

export default function NotificationSnackbar() {
  const notification = useContext(NotificationContext)
  const setNotifications = useContext(NotificationDispatchContext)
  const navigate = useNavigate()

  const {
    type,
    message,
    isDismissable,
    redirect,
    fadeout,
    isActive
  } = notification

  useEffect(() => {
    let timeoutId

    if (fadeout) {
      timeoutId = setTimeout(() => setNotifications({
        ...notification,
        isActive: false,
        fadeout: false
      }), 2500)
    }

    return () => clearTimeout(timeoutId)
  }, [fadeout])

  const onClick = () => {
    if (isDismissable) setNotifications({ ...notification, isActive: false })
    else if (redirect) navigate(redirect)
  }

  return (
    <div
      className={cn(
        style.notifications_container,
        !!isActive && style.visible,
        style[type]
      )}
      onClick={onClick}
    >
      <h4>{message}</h4>
    </div>
  )
}