import algoliasearch from "algoliasearch"
import getAllBrands from "../../actions/getAllBrands"

const client = algoliasearch('CWYZU9AB71', 'xxxxxxxxx');
const index = client.initIndex('brands');

const cleanForAlgolia = brand => {
  const cleanedBrand = {
    objectID: brand.brandId,
    brandId: brand.brandId,
    brandUrl: brand.brandUrl,
    brandRegion: brand.brandRegion,
    brandLogo: brand.brandLogo,
    brandCover: brand.brandCover,
    brandName: brand.brandName,
    ...(brand.brandDescription && { brandDescription: brand.brandDescription }),
  }

  return cleanedBrand
}

export default async function updateAlgolia() {
  try {
    const allBrands = await getAllBrands()
    const brands = Object.values(allBrands)

    const cleanedBrands = brands.map(brand => cleanForAlgolia(brand))
    console.log("cleanedBrands", cleanedBrands)
    const res = await index.saveObjects(cleanedBrands)
    console.log("res", res)

  } catch (error) {
    console.log(error)
  }
}