import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import algoliasearch from 'algoliasearch/lite'

import downarrow from '../../assets/icons/downTriangle.svg'
import style from "./style.module.css"
import { UserContext, UserDispatchContext } from '../../providers/UserProvider'
import { routes } from '../../utils/constants'

const algoliaClient = algoliasearch('CWYZU9AB71', 'faf02662928a7ddfecb9585ee3f230df')

export default function Search({ onClose }) {
  const { userRegion } = useContext(UserContext)
  const setUser = useContext(UserDispatchContext)
  const [search, setSearch] = useState("")
  const [hits, setHits] = useState([])
  const [isSearchActive, setSearchActive] = useState(false)
  const [isCountryMenu, setCountryMenu] = useState(false)
  const [activeCountry, setActiveCountry] = useState("us")
  const navigate = useNavigate()

  useEffect(() => {
    const localStorageCountry = localStorage.getItem("userRegion")
    if (localStorageCountry) setActiveCountry(localStorageCountry)
  }, [])

  useEffect(() => {
    if (userRegion) {
      setActiveCountry(userRegion)
      localStorage.setItem("userRegion", userRegion)
    }
  }, [userRegion])

  const hideHits = () => setSearchActive(false)

  const onQuery = async (e) => {
    const query = e.target.value

    setSearch(query)

    if (!query) {
      setHits([])
      return
    }

    const index = algoliaClient.initIndex("brands")
    const res = await index.search(query, {
      filters: `brandRegion:${activeCountry}`,
      hitsPerPage: 5
    })

    const { hits } = res
    setHits(hits)
  }

  const onClick = (hit) => {
    setSearch("")
    setSearchActive(false)
    navigate(`${routes.ALL_BRANDS}/${hit.brandId}`)
    onClose?.()
  }

  const handleSetActiveCountry = (userRegion) => {
    setActiveCountry(userRegion)
    localStorage.setItem("userRegion", userRegion)
    setUser(prev => ({ ...prev, userRegion }))
  }

  const emptyQuery = !search || hits.length === 0

  return (
    <>
      <div className={cn(
        style.searchWrapper,
        isSearchActive && style.isSearchActive,
        !emptyQuery && style.hasHits,
        userRegion && style.hasUserRegion
      )}>
        <div className={style.search_input_wrapper}>
          {!userRegion && (
            <div
              className={cn(style.countrySelect, isCountryMenu && style.countryActive)}
              onClick={() => setCountryMenu(prev => !prev)}
            >
              <img src={downarrow} className={style.downarrow} alt="arrow" />
              <p className={style.countryCode}>{activeCountry}</p>

              {isCountryMenu && (
                <div className={style.countryDropdown}>
                  <div onClick={() => handleSetActiveCountry("us")}>
                    <p>
                      US
                    </p>
                  </div>
                  <div onClick={() => handleSetActiveCountry("uk")}>
                    <p>
                      UK
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}

          <input
            type="text"
            placeholder="Search brands"
            value={search}
            onChange={onQuery}
            onFocus={() => setSearchActive(true)}
          />
        </div>

        {(!emptyQuery && isSearchActive) && (
          <ul>
            {hits.map(hit => (
              <div className={style.searchHit} onClick={() => onClick(hit)} key={hit.brandId}>
                <img src={hit.brandLogo} alt={hit.brandId} />

                <div className={style.hitText}>
                  <h4>{hit.brandName}</h4>
                  <p>{hit.brandDescription}</p>
                </div>
              </div>
            )
            )}
          </ul>
        )}
      </div>

      {isSearchActive && <div className={style.cancel} onClick={hideHits} />}

      {isCountryMenu && (
        <div
          className={style.dropdownCancel}
          onClick={() => setCountryMenu(false)}
        />
      )}
    </>
  )
}