import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import allBrands from '../../utils/allBrands.json'

import { UserContext } from '../../providers/UserProvider'
import Page from '../../components/Page'
import BrandGrid from '../../components/BrandGrid'
import NextButton from '../../components/NextButton'

import style from "./style.module.css"
import { FOLLOW_LIMIT, routes } from '../../utils/constants'

export default function OnboardFollow() {
  const user = useContext(UserContext)
  const navigate = useNavigate()
  const { nextFollowing = {}, userRegion, userGender } = user

  const onClick = () => navigate(routes.ONBOARD_PICK)

  const brandOptions = userRegion && Object.values(allBrands[userRegion]).filter(brands => brands.brandDepartments?.[userGender])

  return (
    <Page noFooter>
      <section className={style.content}>
        <div className={style.intro}>
          <h2>
            Pick Brands to Follow
          </h2>
          <p>{`Limit of ${FOLLOW_LIMIT} brands`}</p>
        </div>

        <BrandGrid brands={brandOptions} isSelectGrid />
      </section>

      <NextButton
        buttonText="Pick alerts"
        onClick={onClick}
        isActive={Object.keys(nextFollowing).length > 0}
      />
    </Page>
  )
}