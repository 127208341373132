import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import cn from 'classnames'

import allBrands from '../../utils/allBrands'

import { UserContext } from '../../providers/UserProvider'
import Page from '../../components/Page'
import BrandGrid from '../../components/BrandGrid'

import style from "./style.module.css"
import Button from '../../components/Button'
import DropDownMenu from '../../components/DropDownMenu'

export default function AllBrands() {
  const user = useContext(UserContext)
  let [searchParams, setSearchParams] = useSearchParams()
  const [regionFilter, setRegionFilter] = useState("us")
  const [departmentFilter, setDepartmentFilter] = useState(searchParams.get("department") || "all")
  const [brands, setBrands] = useState([])
  const { userRegion } = user

  useEffect(() => {
    const brandOptions = Object.values(allBrands[userRegion || regionFilter]).filter((brands) => {
      if (departmentFilter === "all") return true
      return brands.brandDepartments?.[departmentFilter]
    })

    setBrands(brandOptions)
  }, [regionFilter, departmentFilter])

  return (
    <Page noFooter>
      <section className={style.content}>
        <div className={style.allBrands_container}>
          {/* <div className={style.buttonContainer}>
              <Button className={cn(regionFilter === "us" && style.activeButton, style.button)}
                onClick={() => setRegionFilter("us")}
              >
                US
              </Button>
              <Button className={cn(regionFilter === "uk" && style.activeButton, style.button)}
                onClick={() => setRegionFilter("uk")}
              >
                UK
              </Button>
            </div> */}
          <div className={style.filters}>
            {!userRegion && (
              <div className={style.inputContainer}>
                <label>COUNTRY</label>
                <DropDownMenu
                  className={cn(style.genderSelect)}
                  keyName="userRegion"
                  keyDisplayName="country"
                  onClick={(value) => setRegionFilter(value)}
                  selectedValue={regionFilter}
                  options={[{
                    name: "US",
                    value: "us",
                  },
                  {
                    name: "UK",
                    value: "uk",
                  }]}
                />
              </div>
            )}

            <div className={style.inputContainer}>
              <label>DEPARTMENT</label>
              <DropDownMenu
                className={cn(style.genderSelect)}
                keyName="region"
                keyDisplayName="Department"
                onClick={(key, value) => setDepartmentFilter(value)}
                selectedValue={departmentFilter}
                options={[{
                  name: "All",
                  value: "all",
                },
                {
                  name: "Women's",
                  value: "womens",
                },
                {
                  name: "Men's",
                  value: "mens",
                }]}
              />
            </div>
          </div>


          <div>
            <BrandGrid brands={brands} />
          </div>
        </div>
      </section>
    </Page>
  )
}