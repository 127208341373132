import React from 'react'
import cn from 'classnames'

import style from "./style.module.css"

export default function FeedTabs({
  activeTab,
  setActiveTab,
  country
}) {

  return (
    <div className={style.tabBar}>
      <button
        onClick={() => setActiveTab("all")}
        className={cn(style.tab, activeTab === "all" && style.activeTab)}
      >
        ALL
      </button>
      <button
        onClick={() => setActiveTab("sales")}
        className={cn(style.tab, activeTab === "sales" && style.activeTab)}
      >
        SALES
      </button>
      <button
        onClick={() => setActiveTab("drops")}
        className={cn(style.tab, activeTab === "drops" && style.activeTab)}
      >
        DROPS
      </button>
      <button
        onClick={() => setActiveTab("coupons")}
        className={cn(style.tab, activeTab === "coupons" && style.activeTab)}
      >
        {country === "uk" ? "PROMO CODES" : "COUPONS"}
      </button>
    </div>
  )
}