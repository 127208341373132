import React from 'react'

import SignUpForm from '../SignUpForm'

import style from "./style.module.css"

const SignInUpModal = ({ setModal, type }) => {
  const headerText = type === 'signUp' ? 'Welcome to Snappi' : 'Welcome back'
  const subText = 'Never miss a sale or a drop again!'

  return (
    <div className={style.signUp}>
      <div className={style.signUp_text}>
        <h3>{headerText}</h3>
        <p>{subText}</p>
      </div>

      <SignUpForm
        isSignIn={type === 'signIn'}
        setModal={setModal}
        isModal
      />
    </div>
  )

}

export default SignInUpModal