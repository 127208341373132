import {
  doc,
  updateDoc
} from "firebase/firestore"
import {
  db
} from ".."
import { COLLECTIONS } from '../utils/constants'

export default async function updateLastLogin(userId) {
  try {
    const userRef = doc(db, COLLECTIONS.USERS, userId)
    await updateDoc(userRef, {
      userLastLogin: Date.now()
    });
  } catch (error) {
    console.log("Error updating user:", error)
  }
}