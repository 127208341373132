// SOURCE OF TRUTH FOR PLANS
// BUT!!! CHANGES NEED TO BE MANUALLY UPDATED WITH STRIPE!!!!

import { checkEnv } from "./helpers"

const PRICE_ID = {}

const {
  isDev,
  isStaging,
  isProd
} = checkEnv()

if (isDev) {
  PRICE_ID.INSIDER = "price_1MTzKRLoZVX2MzfCAHQbI8GY"
  PRICE_ID.SHOPPER = "price_1MTzKELoZVX2MzfCMqL79Jtn"
  PRICE_ID.SAVER = "price_1MTzJzLoZVX2MzfCJGsNXXvn"
  PRICE_ID.BUDGETER = "price_1MTzTKLoZVX2MzfCwlaWkhzl"
} else if (isStaging) {
  PRICE_ID.INSIDER = "price_1MdnQ7LSGL2qUu45voNAU5ms"
  PRICE_ID.SHOPPER = "price_1MdnRCLSGL2qUu459Q2N0B3l"
  PRICE_ID.SAVER = "price_1MdnRmLSGL2qUu45nHSBcpWs"
  PRICE_ID.BUDGETER = "price_1MdnSLLSGL2qUu45WJhg9a0w"
} else if (isProd) {
  PRICE_ID.INSIDER = "price_1MVsGILoZVX2MzfCBExwhDge"
  PRICE_ID.SHOPPER = "price_1MTz7ZLoZVX2MzfCw3Wml6kn"
  PRICE_ID.SAVER = "price_1MTz77LoZVX2MzfChkREU8Ml"
  PRICE_ID.BUDGETER = "price_1MVsFJLoZVX2MzfCRqOAe2FH"
}

const plans = {
  insider: {
    title: "Insider",
    id: "insider",
    price: "$7 / Month",
    priceId: PRICE_ID.INSIDER,
    description: "",
    followLimit: 30,
    htmlDescription: (
      <>
        <p>• Sales alerts from 30 brands</p>
        <p>• Text message alerts</p>
        <p>• All-access sales data in web app</p>
        <p>• Unlimited social group and forum access</p>
      </>
    )
  },
  shopper: {
    title: "Shopper",
    id: "shopper",
    price: "$5 / Month",
    priceId: PRICE_ID.SHOPPER,
    description: "",
    followLimit: 5,
    htmlDescription: (
      <>
        <p>• Sales alerts from 5 brands</p>
        <p>• Text message alerts</p>
        <p>• Limited sales data in web app</p>
        <p>• Limited social group and forum access</p>
      </>
    )
  },
  saver: {
    title: "Saver",
    id: "saver",
    price: "$3 / Month",
    priceId: PRICE_ID.SAVER,
    description: "",
    followLimit: 3,
    htmlDescription: (
      <>
        <p>• Sales alerts from 3 brands</p>
        <p>• Limited social group and forum access</p>
      </>
    )
  },
  budgeter: {
    title: "Budgeter",
    id: "budgeter",
    price: "Free",
    priceId: PRICE_ID.BUDGETER,
    description: "",
    followLimit: 1,
    htmlDescription: (
      <>
        <p>• Sales alerts from 1 brand</p>
      </>
    )
  },
}

export default plans