import { parsePhoneNumber } from 'awesome-phonenumber'

// import plans from "./plans"

export const getTodaysDate = () => {
  var tzoffset = (new Date()).getTimezoneOffset() * 60000 //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1)
  const today = localISOTime.split('T')[0]
  return today
}

export const convertDateToUnixOfCountry = (date, brandRegion, isEndInclusive) => {
  const genericDate = new Date(date)

  const offset = brandRegion === "us" ? -5 : 0
  const timezoneOffset = offset * 60 // offset in minutes (UTC-04:00)

  // set the timezone of the Date object to the specific region's timezone
  genericDate.setUTCMinutes(genericDate.getUTCMinutes() - timezoneOffset)

  // set the hours, minutes, seconds, and milliseconds of the Date object to the end of the day
  if (isEndInclusive) genericDate.setUTCHours(23 - offset, 59, 59, 999)

  // get the Unix time of the Date object
  const unixTime = Math.floor(genericDate.getTime() / 1000)

  return unixTime
}

export const formatDate = (date) => {
  const formattedDate = new Date(date).toLocaleDateString("en-US")
  return formattedDate
}

export const checkIsPast = (date) => {
  const now = Date.now()
  return date < now
}

export const checkIsFuture = (date) => {
  const now = Date.now()
  return date > now
}

// export const checkIsUpgrade = (nextPlanId, prevPlanId) => {
//   const prevPlan = plans[nextPlanId]
//   const nextPlan = plans[prevPlanId]
//   const prevCount = prevPlan.followLimit
//   const nextCount = nextPlan.followLimit

//   if (prevCount > nextCount) {
//     return true
//   } else if (prevCount === nextCount) {
//     const isPaid = nextPlan.id === "saver"
//     return !!isPaid
//   } else {
//     return false
//   }
// }

// export const checkIsPremiumPlan = (planId) => {
//   if (planId === plans.shopper.id || planId === plans.insider.id) return true
//   else return false
// }

export const checkIsPremiumMethod = (userMethod) => {
  if (userMethod === "text" || userMethod === "both") return true
  else return false
}

export const isValidPhoneNumber = (phone) => {
  const { regionCode, valid } = parsePhoneNumber(phone)
  if ((regionCode === "US" || regionCode === "GB") && valid) return true
  else return false
}

export const checkEnv = () => {
  let isDev
  let isStaging
  let isProd

  switch (window.location.hostname) {
    case "localhost":
      isDev = true
      break
    case "snappi.vercel.app":
      isStaging = true
      break
    case "snappi.club":
      isDev = true
      break
    default:
      isProd = true
      break
  }

  return {
    isDev,
    isStaging,
    isProd
  }
}

export const getUrl = (saleUrl, userGender, brandUrl) => {
  const oppositeGender = userGender === 'mens' ? 'womens' : 'mens'
  const url = saleUrl?.[userGender] ||
  saleUrl?.[oppositeGender] ||
    saleUrl?.general ||
    // saleUrl ||
    brandUrl

  return url
}