import React, { useContext, useState, useRef } from 'react'
import cn from 'classnames'

import Button from '../Button'
import { ModalDispatchContext } from '../../providers/ModalProvider'
import createSale from '../../actions/createSale'
import updateSale from '../../actions/updateSale'
import style from "./style.module.css"
import DropDownMenu from '../DropDownMenu'

export default function SaleForm({ brand, editSale: saleToEdit }) {
  const salesImageRef = useRef()
  const [activeStartDate, setActiveStartDate] = useState("now")
  const [sale, setSale] = useState({})
  const [editSale, setEditSale] = useState(saleToEdit)
  const [isLoading, setIsLoading] = useState(false)
  const [saleImage, setSalesImage] = useState()
  const [preview, setPreview] = useState()

  const {
    brandName,
    brandLogo,
    brandCover,
    brandUrl,
    brandRegion,
    brandId,
  } = brand || {}

  const setModal = useContext(ModalDispatchContext)

  const handlePicInput = (e) => {
    setSalesImage(e.target.files[0])

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setPreview(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
  }

  const handleCreateSale = async () => {
    if (!sale.saleHeader) return alert('Please fill in the sale header')
    if (!sale.saleDepartments.mens && !sale.saleDepartments.womens) return alert('Please fill in the sale departments')
    setIsLoading(true)

    if (sale.salePercentage) sale.salePercentage = Number(sale.salePercentage)

    await createSale(brandId, {
      ...sale,
      brandName,
      brandCover,
      brandLogo,
      brandId,
      brandUrl,
      brandRegion,
    }, saleImage, activeStartDate)

    setModal({ isOpen: false })
    setIsLoading(false)
  }

  const handleEditSale = async () => {
    // check dirty state
    setIsLoading(true)

    // TODO: can't edit start date, saleImage
    // DON'T CHANGE: brandName, brandCover, brandLogo, brandUrl, brandRegion, brandId
    if (editSale.salePercentage) editSale.salePercentage = Number(editSale.salePercentage)
    await updateSale(brandId, editSale)

    setModal({ isOpen: false })
    setIsLoading(false)
  }

  const handleUpdateSale = editSale ? setEditSale : setSale
  const handleSaveSale = editSale ? handleEditSale : handleCreateSale
  const headerText = editSale ? 'Edit Sale' : 'Create Sale'
  const buttonText = editSale ? 'Update sale' : 'Create sale'

  return (
    <>
      <div className={style.signUp_form}>
        <h3>{headerText}</h3>

        {!editSale && (
          <div className={style.uploadContainer}>
            {preview ? <img className={style.pic_preview} src={preview} alt="cover" />
              : <div className={style.pic_preview} />}

            <Button className={style.filePickerButton} onClick={() => salesImageRef.current.click()}>
              Add sales image
            </Button>

            <input
              hidden
              ref={salesImageRef}
              className={style.imageUpload}
              onChange={handlePicInput}
              accept="image/*"
              type="file"
            />

            <p>{saleImage?.name}</p>
          </div>
        )}

        <label htmlFor="saleType">Type of sale</label>
        <DropDownMenu
          className={cn(style.input)}
          keyName="saleType"
          keyDisplayName="sale type"
          onClick={(key, value) => handleUpdateSale(prev => ({
            ...prev,
            [key]: value
          }))}
          selectedValue={editSale?.saleType || sale.saleType}
          options={[{
            name: "Seasonal",
            value: "seasonal",
          },
          {
            name: "Flash",
            value: "flash",
          },
          {
            name: "General",
            value: "general",
          },
          {
            name: "Member",
            value: "member",
          }]}
        />

        <label htmlFor="saleType">Department</label>
        <div className={cn(style.departmentContainer, "dtRowCenter")}>
          <div className={cn(style.department, "dtRowCenter")}>
            <input
              type="checkbox"
              id="mens"
              name="mens"
              value="true"
              onChange={e => handleUpdateSale(prevSale =>
              ({
                ...prevSale,
                saleDepartments: {
                  ...prevSale.saleDepartments,
                  mens: !prevSale.saleDepartments?.mens,
                }
              }))}
            />
            <label htmlFor="mens">Men's</label><br />
          </div>

          <div className={cn(style.department, "dtRowCenter")}>
            <input
              type="checkbox"
              id="womens"
              name="womens"
              value="true"
              onChange={e => handleUpdateSale(prevSale =>
              ({
                ...prevSale,
                saleDepartments: {
                  ...prevSale.saleDepartments,
                  womens: !prevSale.saleDepartments?.womens,
                }
              }))}
            />
            <label htmlFor="womens">Women's</label><br />
          </div>
        </div>

        <label htmlFor="header">Sale header*</label>
        <input
          name="header"
          type="text"
          {...editSale && { defaultValue: editSale.saleHeader }}
          onChange={e => handleUpdateSale((prev) => (({
            ...prev,
            saleHeader: e.target.value,
          })))}
        />

        <label htmlFor="description">Sale description</label>
        <input
          name="description"
          type="text"
          {...editSale && { defaultValue: editSale.saleDescription }}
          onChange={e => handleUpdateSale((prev) => (({
            ...prev,
            saleDescription: e.target.value,
          })))}
        />

        <label htmlFor="percentage">Sale percentage</label>
        <input
          name="percentage"
          type="text"
          {...editSale && { defaultValue: editSale.salePercentage }}
          onChange={e => handleUpdateSale((prev) => (({
            ...prev,
            salePercentage: e.target.value,
          })))}
        />

        <label htmlFor="generalUrl">General Sale URL (Will default to brand home page if empty)</label>
        <input
          name="generalUrl"
          type="text"
          {...editSale && { defaultValue: editSale.saleUrl?.general }}
          onChange={e => handleUpdateSale((prev) => ({
            ...prev,
            saleUrl: {
              ...prev.saleUrl,
              general: e.target.value,
            }
          }))}
        />

        <label htmlFor="womensUrl">Women's Sale URL</label>
        <input
          name="womensUrl"
          type="text"
          {...editSale && { defaultValue: editSale.saleUrl?.womens }}
          onChange={e => handleUpdateSale((prev) => ({
            ...prev,
            saleUrl: {
              ...prev.saleUrl,
              womens: e.target.value,
            }
          }))}
        />

        <label htmlFor="mensUrl">Men's Sale URL</label>
        <input
          name="mensUrl"
          type="text"
          {...editSale && { defaultValue: editSale.saleUrl?.mens }}
          onChange={e => handleUpdateSale((prev) => ({
            ...prev,
            saleUrl: {
              ...prev.saleUrl,
              mens: e.target.value,
            }
          }))}
        />

        <label htmlFor="saleCode">Sale code</label>
        <input
          name="saleCode"
          type="text"
          {...editSale && { defaultValue: editSale.saleCode }}
          onChange={e => handleUpdateSale((prev) => (({
            ...prev,
            saleCode: e.target.value,
          })))}
        />

        {editSale && (
          <label htmlFor="saleStartDate">Sale start date*</label>
        )}

        {!editSale && (
          <div className={style.saleStartDate}>
            <div
              onClick={() => setActiveStartDate("now")}
              className={cn(activeStartDate === "now" && style.activeStartDate)}
            >
              Now
            </div>
            <div
              onClick={() => setActiveStartDate("else")}
              className={cn(activeStartDate === "else" && style.activeStartDate)}
            >
              Else
            </div>
          </div>
        )}

        {(activeStartDate === "else" || editSale) && (
          <input
            name="saleStartDate"
            type="date"
            defaultValue={sale.saleStartDate}
            onChange={e => handleUpdateSale((prev) => ({
              ...prev,
              saleStartDate: e.target.value,
            }))}
          />
        )}

        <label htmlFor="saleEndDate">Sale end date (the last day the sale is running)</label>
        <input
          name="saleEndDate"
          type="date"
          onChange={e => handleUpdateSale((prev) => ({
            ...prev,
            saleEndDate: e.target.value,
          }))}
        />
      </div>

      <Button
        onClick={handleSaveSale}
        className={style.button}
        isLoading={isLoading}
      >
        {buttonText}
      </Button>
    </>
  )
}