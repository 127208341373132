import {
  collection,
  query,
  getDocs,
  orderBy
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'

export default async function getAllActiveSales() {
  const activeSales = {}

  const q = query(collection(db, COLLECTIONS.ACTIVE_SALES), orderBy("saleStartDate", "desc"))
  const querySnapshot = await getDocs(q)

  querySnapshot.forEach((doc) => {
    const data = doc.data()
    data.saleId = doc.id
    activeSales[doc.id] = data
  })

  return activeSales
}