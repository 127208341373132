import {
  collection,
  query,
  getDocs,
  orderBy,
  where,
  limit
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS } from '../utils/constants'

export default async function getMostRecentSales() {
  const activeDrops = []

  const now = new Date()
  const fiveDaysAgoDate = new Date(now.getTime() - (5 * 24 * 60 * 60 * 1000))
  const fiveDaysAgo = Math.floor(fiveDaysAgoDate.getTime())

  const q = query(
    collection(db, COLLECTIONS.ALL_DROPS),
    orderBy("dropDate", "desc"),
    where("dropDate", ">", fiveDaysAgo),
    limit(20)
  )
  const querySnapshot = await getDocs(q)

  querySnapshot.forEach((doc) => {
    const data = doc.data()
    data.dropId = doc.id
    activeDrops.push(data)
  })

  return activeDrops
}