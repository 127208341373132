import {
  getDownloadURL,
  uploadBytesResumable,
  ref
} from "firebase/storage"
import { storage } from ".."

function resizedName(rawUrl, dimensions) {
  const pathname = new URL(rawUrl).pathname
  const splits = pathname.split('/')
  const oldPath = splits[5]
  const newpath = `${oldPath}_${dimensions}`
  const url = rawUrl.replace(oldPath, newpath)
  return url
}

export default async function uploadImage(props) {
  const { type, path, image } = props

  return new Promise(function (resolve, reject) {
    if (!type || !image) return alert('No file or image type')

    const file = image

    // logo/acneStudios.png or cover/acneStudios.png
    const storageRef = ref(storage, `${type}/${path}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      "state_changed",
      function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
      },
      function error(err) {
        console.log('error', err)
        reject()
      },
      async () => {
        let size
        switch (type) {
          case "cover":
            size = '1500x500'
            break;
          case "logo":
            size = '175x175'
            break;
          case "sales":
            size = '600x600'
            break;
          case "drops":
            size = '1000x1000'
            break;
          default:
            break;
        }

        const rawUrl = await getDownloadURL(uploadTask.snapshot.ref)
        const url = resizedName(rawUrl, size)
        resolve({ url })
      }
    )
  })
}
