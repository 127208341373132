import React from 'react'
import { Link } from 'react-router-dom'

const WithLink = ({ children, to, className, onClick, disableLink, url }) => {
  if (url && !disableLink) return (
    <a
      href={url}
      className={className}
      target="_href"
      rel="noopener"
    >
      {children}
    </a>
  )

  if (to && !disableLink) return (
    <Link to={to} className={className}>
      {children}
    </Link>
  )

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  )
}

export default WithLink