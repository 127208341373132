import React, { useContext, useEffect, useState } from 'react'
import cn from 'classnames'

import { UserContext, UserDispatchContext } from '../../providers/UserProvider'
import Button from '../Button'
import loadingBlack from '../../assets/icons/loadingBlack.svg'
import loadingWhite from '../../assets/icons/loadingWhite.svg'
import updateFollowBrand from '../../actions/updateFollowBrand'
import { ModalDispatchContext } from '../../providers/ModalProvider'
import { FOLLOW_LIMIT } from '../../utils/constants'

import style from './style.module.css'

export default function FollowButton({ brand, className, isBrandDashboard }) {
  const user = useContext(UserContext)
  const setUser = useContext(UserDispatchContext)
  const setModal = useContext(ModalDispatchContext)

  const { following = {}, userId, isAdmin } = user
  const { brandId } = brand || {}

  const [buttonText, setButtonText] = useState("Follow")
  const [isLoading, setIsLoading] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [hasClicked, setHasClicked] = useState(false)

  const isFollowing = !!following[brandId]

  useEffect(() => {
    let text

    if (isFollowing && !isLoading) text = "Following"
    else if (isFollowing && isLoading) text = "Following"
    else if (!isFollowing && !isLoading) text = "Follow"
    else if (!isFollowing && isLoading) text = "Unfollowing"

    setButtonText(text)
  }, [isFollowing, isLoading])

  const onMouseEnter = () => {
    setIsHovering(true)
    setHasClicked(false)
    if (!isFollowing) return
    setButtonText("Unfollow")
  }

  const onMouseLeave = () => {
    setIsHovering(false)
    setHasClicked(false)
    if (!isFollowing) return
    setButtonText("Following")
  }

  const handleOnClick = async (e) => {
    e.preventDefault()
    if (!userId) return setModal({ isOpen: true, type: 'signUp' })
    const mustSwapBrand = Object.keys(following).length === FOLLOW_LIMIT && !isAdmin
    if (mustSwapBrand && !isFollowing) return setModal({ isOpen: true, type: 'swapFollowing', brand })
    setHasClicked(true)

    // MANAGE UI STATE
    const nextFollowing = { ...following }
    if (isFollowing) delete nextFollowing[brandId]
    else nextFollowing[brandId] = brand
    setUser(prevUser => ({
      ...prevUser,
      following: nextFollowing,
    }))

    // MANAGE DB STATE
    setIsLoading(true)
    if (isFollowing) await await updateFollowBrand({ unfollowBrandId: brandId })
    else await await updateFollowBrand({ followBrand: brand })
    setIsLoading(false)
  }

  const loadingIcon = isFollowing ? loadingBlack : loadingWhite

  return (
    <Button
      onClick={handleOnClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={isLoading}
      className={cn(
        className, style.followButton,
        (!isFollowing && !isHovering && !hasClicked && !isLoading && isBrandDashboard) && [style.bgNeon, style.textBlack],

        (isFollowing && !isHovering && !hasClicked && !isLoading) && [style.bgBlack, style.textWhite],
        (isFollowing && isHovering && !hasClicked && !isLoading) && [style.bgPurple, style.textWhite],
        (!isFollowing && isHovering && hasClicked && isLoading) && [style.bgPurple, style.textWhite],
        (!isFollowing && isHovering && hasClicked && !isLoading) && [style.bgWhite, style.textBlack],

        (!isFollowing && !isHovering && !hasClicked && !isLoading) && [style.bgWhite, style.textBlack],
        (!isFollowing && isHovering && !hasClicked && !isLoading) && [style.bgNeon, style.textBlack],
        (isFollowing && isHovering && hasClicked && isLoading) && [style.bgNeon, style.textBlack],
        (isFollowing && isHovering && hasClicked && !isLoading) && [style.bgBlack, style.textWhite],
      )}
    >
      {buttonText}

      {isLoading ? (
        <img src={loadingIcon} alt="loading" className={style.loading} />
      ) : ""}
    </Button>
  )
}