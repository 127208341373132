import React from 'react'
import cn from 'classnames'

import loading from '../../assets/icons/loadingWhite.svg'

import style from "./style.module.css"

export default function Button({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  disabled,
  isLoading
}) {
  return (
    <button
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn(style.button, className)}
      disabled={disabled}
    >
      {children}
      {isLoading && (
        <img
          src={loading}
          alt="loading"
          className={style.loadingSave}
        />
      )}
    </button>
  )
}