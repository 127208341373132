import {
  collection,
  query,
  getDocs,
  orderBy,
  where
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS } from '../utils/constants'

export default async function getMyActiveCoupons(brandIds) {
  const activeCoupons = []
  const maxLength = 10
  const splitBrands = []

  for (let i = 0; i < brandIds.length; i += maxLength) {
    splitBrands.push(brandIds.slice(i, i + maxLength))
  }

  const activeCouponsRef = collection(db, COLLECTIONS.ACTIVE_COUPONS)

  const resultPromises = []
  splitBrands.forEach(async (brandIds) => {
    // QUERY CANNOT HAVE MORE THAN TEN BRAND IDS IN OPERATOR
    const q = query(activeCouponsRef, orderBy("couponStartDate", "desc"), where("brandId", "in", [...brandIds]))
    const docs = getDocs(q)
    resultPromises.push(docs)
  })

  const queries = await Promise.all(resultPromises)
  queries.forEach((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const data = doc.data()
      data.saleId = doc.id
      activeCoupons.push(data)
    })
  })

  return activeCoupons
}