import {
  collection,
  doc,
  query,
  getDoc,
  getDocs
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'
import getUserFollowing from "./getUserFollowing"

export default async function getMe(userId) {
  const docRef = doc(db, COLLECTIONS.USERS, userId)
  const docSnap = await getDoc(docRef)

  if (!docSnap.exists()) return {}

  // GET FOLLOWING
  const [following, followingArray] = await getUserFollowing(userId)

  // GET LIKES
  // const likesRef = query(collection(db, COLLECTIONS.USERS, userId, COLLECTIONS.LIKES))
  // const likesSnapshot = await getDocs(likesRef)
  // const liked = {}
  // likesSnapshot.forEach((doc) => liked[doc.id] = doc.data())

  const me = {
    ...docSnap.data(),
    following,
    // liked,
  }

  return me
}