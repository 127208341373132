import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import Marquee from "react-fast-marquee"
import 'swiper/css';
import "swiper/css/pagination";

import Button from '../../components/Button'
import Page from '../../components/Page'

import { UserContext, UserDispatchContext } from '../../providers/UserProvider'

import Logos from '../../assets/images/Logos.jpg'
import Madwell from '../../assets/images/madewell.webp'
import Lacoste from '../../assets/images/lacoste.jpg'
import Brand from '../../assets/images/brand.jpg'
import SoldOut from '../../assets/images/SoldOut.jpg'
import Notification from '../../assets/images/notification.jpg'
import Mens from '../../assets/images/mens.jpg'
import Womens from '../../assets/images/womens.jpg'
import Supreme from '../../assets/images/Supreme.png'
import style from "./style.module.css"
import allBrands from '../../utils/allBrands.json';
import { routes } from '../../utils/constants';
import WithLink from '../../components/WithLink';
import Logo from '../../assets/icons/snappi.svg'
import getMostRecentSales from '../../actions/getMostRecentSales';
import getMostRecentDrops from '../../actions/getMostRecentDrops';
import LandingSaleTile from '../../components/LandingSaleTile';
import LandingDropTile from '../../components/LandingDropTile';
import ContentCard from '../../components/ContentCard';
import BrandNugget from '../../components/BrandNugget';
import { getUrl } from '../../utils/helpers';

export default function Landing() {
  const { userId, userGender, userRegion, isLoaded } = useContext(UserContext)
  const setUser = useContext(UserDispatchContext)
  const [brands, setBrands] = useState([])
  const [activeCountry, setActiveCountry] = useState("us")
  const [recentSales, setRecentSales] = useState([])
  const [recentDrops, setRecentDrops] = useState([])
  const [allRecentSales, setAllRecentSales] = useState([])
  const [allRecentDrops, setAllRecentDrops] = useState([])
  const [rankedSales, setRankedSales] = useState([])
  const [featuredSale, setFeaturedSale] = useState({})
  const [featuredDrop, setFeaturedDrop] = useState({})
  const { us, uk } = allBrands

  useEffect(() => {
    if (!isLoaded) return

    const localStorageCountry = localStorage.getItem("userRegion")
    const thisCountry = userRegion || localStorageCountry || 'us'
    setActiveCountry(thisCountry)

    const handleGetSales = async () => {
      const sales = await getMostRecentSales()
      setAllRecentSales(sales)
      setRecentSales(sales.filter(sale => sale.brandRegion === thisCountry))
    }

    const handleGetDrops = async () => {
      const drops = await getMostRecentDrops()
      setAllRecentDrops(drops)
      setRecentDrops(drops.filter(drop => drop.brandRegion === thisCountry))
    }

    handleGetSales()
    handleGetDrops()

    const all = []
    Object.values(us).forEach((brand) => all.push(brand))
    Object.values(uk).forEach((brand) => all.push(brand))
    setBrands(all)
  }, [isLoaded])

  useEffect(() => {
    if (recentSales.length === 0) return

    const sortedSales = [...recentSales].sort(function compareByNestedValue(obj1, obj2) {
      const value1 = obj1.salePercentage;
      const value2 = obj2.salePercentage;

      if (value1 === undefined && value2 === undefined) return 0;
      if (value1 === undefined) return 1;
      if (value2 === undefined) return -1;
      return value2 - value1;
    })

    const cleanedSortedSales = sortedSales.filter(sale => sale.salePercentage).slice(0, 14)

    setRankedSales(cleanedSortedSales)
    setFeaturedSale(recentSales[Math.floor(Math.random() * (recentSales.length - 1) - 0)])
  }, [recentSales])

  useEffect(() => {
    if (recentDrops.length === 0) return

    setFeaturedDrop(recentDrops[Math.floor(Math.random() * (recentDrops.length - 1) - 0)])
  }, [recentDrops])

  useEffect(() => {
    if (!userRegion) return

    const regionRecentDrops = allRecentDrops.filter(drop => drop.brandRegion === userRegion)
    setRecentDrops(regionRecentDrops)
    setFeaturedDrop(regionRecentDrops[Math.floor(Math.random() * (recentDrops.length - 1) - 0)])

    const regionRecentSales = allRecentSales.filter(sale => sale.brandRegion === userRegion)
    setRecentSales(regionRecentSales)
    setFeaturedSale(regionRecentSales[Math.floor(Math.random() * (recentSales.length - 1) - 0)])
  }, [userRegion])

  return (
    <Page contentClassName={cn(style.contentClassName, "columnCenter")} pageClassName={style.hub}>
      <section className={cn(style.hero)}>
        <div className={cn(style.heroLeft, style.module, "columnCenter")}>
          <img src={Brand} alt="brand" className={style.imageBg} />

          <div className={cn(style.info, "dtColumnLeft", "mbColumnCenter")}>

            <h1>Never miss a sale or a drop again...</h1>
            <p>
              Instant email alerts from your favorite brands.
            </p>


            <Link to={userId ? routes.ALL_BRANDS : routes.ONBOARD_ACCOUNT}>
              <Button>
                Get Snappi
              </Button>
            </Link>
          </div>
        </div>

        <div className={cn(style.heroRight)}>
          <div className={cn(style.drop, style.module, style.explainerSection, "dtRowCenter")}>
            <img src={Madwell} alt="Drop" className={cn(style.explainer, style.imageBg)} />
            <h2 className={cn(style.titleBig, style.explainerTitle)}>DROPS</h2>

            <div className={style.explainerText}>
              <h2>
                INSTANT ALERTS <br /> FOR <br /> NEW PIECES & COLLECTIONS
              </h2>
            </div>
          </div>

          <div className={cn(style.sale, style.module, style.explainerSection, "dtRowCenter")}>
            <img src={Lacoste} alt="Drop" className={cn(style.explainer, style.imageBg)} />
            <h2 className={cn(style.titleBig, style.explainerTitle)}>SALES</h2>

            <div className={style.explainerText}>
              <h2>
                INSTANT ALERTS <br /> FOR <br /> SALES & MARKDOWNS
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(style.section, 'dtRowCenter')}>
        <div className={cn(style.brands, style.module, "columnCenter")}>
          <h3 className={style.titleMedium}>Over 200+ Brands on Snappi</h3>

          <div className={style.brandMarquee}>
            <Marquee gradient={false} speed={5}>
              {brands.map((brand) => (
                <Link to={`${routes.ALL_BRANDS}/${brand.brandId}`} key={brand.brandId}>
                  <img
                    src={brand.brandLogo}
                    alt="logo"
                    className={style.brandIcon}
                    key={brand.brandId}
                  />
                </Link>
              ))}
            </Marquee>
          </div>
        </div>
      </section>

      <section className={cn(style.section, 'dtRowCenter')}>
        <div className={cn(style.how, style.module, 'columnCenter')}>
          <h3 className={cn(style.titleBig, style.centeredTitle)}>How Snappi Works</h3>

          <div className={cn(style.howSteps)}>
            <div className={cn(style.step, "columnLeft")}>
              <h2 className={style.titleBig}>1. Follow Brands</h2>
              <p>With hundreds of brands to choose from we’ve got you covered no matter your style.</p>
            </div>

            <div className={cn(style.step, "columnLeft")}>
              <h2 className={style.titleBig}>2. Snappi Scans Brands</h2>
              <p>We do the hard work. We’re constantly scanning for new sales and product drops.</p>
            </div>

            <div className={cn(style.step, "columnLeft")}>
              <h2 className={style.titleBig}>3. Snappi Alerts You</h2>
              <p>Whenever a sale or product drop lands we’ll notify you instantly by email.</p>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(style.section, style.recentSection)}>
        <div className={cn(style.recent, 'columnCenter')}>
          <div className={cn(style.recentDrops, style.module)}>
            <h3 className={cn(style.titleMedium, style.greyTitle, style.noPaddingTitle)}>Recent Drops</h3>
            <div className={cn(style.scrollContainer, "rowLeft")}>
              {recentDrops.map(drop => <ContentCard className={style.dropCard} drop={drop} key={drop.dropId} />)}
            </div>
          </div>

          <div className={cn(style.recentSales, style.module)}>
            <h3 className={cn(style.titleMedium, style.greyTitle, style.noPaddingTitle)}>Recent Sales</h3>
            <div className={cn(style.scrollContainer, "rowLeft")}>
              {recentSales.map(sale => <ContentCard className={style.dropCard} sale={sale} key={sale.saleId} />)}
            </div>
          </div>
        </div>

        <div className={cn(style.highestSales, style.module)}>
          <h3 className={cn(style.titleMedium, style.greyTitle)}>Biggest Sales</h3>

          <div className={cn(style.rankedBrands, "columnTopLeft")}>
            <div className={cn(style.tableHeader, "rowCenter")}>
              <p>% OFF</p>
              <p>BRAND</p>
            </div>

            {rankedSales.map(({ salePercentage, brandId, brandName, brandLogo, saleUrl, brandUrl }) => (
              <a
                href={getUrl(saleUrl, userGender, brandUrl)}
                className={cn(style.rankedSaleContainer, "rowCenter")}
                key={brandId}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={cn(style.salePercentage, "rowCenter")}>{`${salePercentage}%`}</div>

                <BrandNugget brand={{ brandId, brandName, brandLogo }} isSelected disableLink />
              </a>
            ))}
          </div>
        </div>
      </section>

      <section className={cn(style.section, 'dtRowCenter')}>
        <div className={cn(style.why, style.module, 'columnCenter')}>
          <h3 className={cn(style.titleBig, style.centeredTitle)}>Why Snappi?</h3>

          <div className={cn(style.reasonsContainer, "dtRowTopSpaceBetween", "mbColumnCenter")}>
            <div className={cn(style.whySection, "columnCenter")}>
              <img src={SoldOut} alt="why" className={cn(style.whyBg)} />

              <div className={cn("columnLeft")}>
                <h4>
                  No more missed sizes.
                </h4>
                <p>
                  Get to every sale and drop before the good stuff goes!
                </p>
              </div>
            </div>

            <div className={cn(style.whySection, "columnCenter")}>
              <div className={cn(style.whyBg, "columnCenter")}>
                <h5>
                  10 brand newsletters
                  <br />
                  =
                  <br />
                  100s of unwanted emails
                  <br />
                  a month
                </h5>
              </div>

              <div className={cn("columnLeft")}>
                <h4>
                  No more inbox clutter.
                </h4>
                <p>
                  Keep newsletters from flooding your inbox.
                </p>
              </div>
            </div>

            <div className={cn(style.whySection, "columnCenter")}>
              <img src={Logos} alt="why" className={cn(style.whyBg)} />

              <div className={cn("columnLeft")}>
                <h4>
                  All your fave brands.
                </h4>
                <p>
                  Manage from one simple platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(style.section, style.featured, 'dtRowCenter', 'mbColumnCenter')}>
        <Link
          to={`brands/${featuredDrop?.brandId}`}
          target="_blank"
          rel="noopener noreferrer"
          className={cn(style.featuredDrop, style.module)}
        >
          <h2 className={style.titleBig}>Featured Drop</h2>
          <img src={featuredDrop?.dropImages?.[0]} alt="Drop" className={cn(style.imageBg, style.imageContain)} />

          <div className={cn(style.featuredInfo, "columnRight")}>
            <div className={style.featuredInfoTag}>
              {featuredDrop?.dropType}
            </div>

            <div className={cn(style.featuredBrandContainer, "dtRowCenter", "mbColumnRight")}>
              <div className={cn(style.featuredBrand, "rowCenter")}>
                <img src={featuredDrop?.brandLogo} alt="brand" />
                <h4>{featuredDrop?.brandName}</h4>
              </div>

              <h2>{featuredDrop?.dropHeader}</h2>
            </div>
          </div>
        </Link>

        <Link
          to={`brands/${featuredSale?.brandId}`}
          href={getUrl(featuredSale?.saleUrl, userGender, featuredSale?.brandUrl)}
          className={cn(style.featuredSale, style.module)}
        >
          <h2 className={style.titleBig}>Featured Sale</h2>
          <img src={featuredSale?.saleImage} alt="Drop" className={cn(style.imageBg, style.imageContain)} />

          <div className={cn(style.featuredInfo, "columnRight")}>
            <div className={style.featuredInfoTag}>
              {`${featuredSale?.salePercentage}%`}
            </div>

            <div className={cn(style.featuredBrandContainer, "dtRowCenter", "mbColumnRight")}>
              <div className={cn(style.featuredBrand, "rowCenter")}>
                <img src={featuredSale?.brandLogo} alt="brand" />
                <h4>{featuredSale?.brandName}</h4>
              </div>

              <h2>{featuredSale?.saleHeader}</h2>
            </div>
          </div>
        </Link>
      </section>


      {/* <section className={cn(style.section, style.recentSection, 'dtRowCenter')}>
        <div className={cn(style.popularBrands, style.module)}>
          <h3 className={style.titleMedium}>Popular Brands</h3>

        </div>

        <div className={cn(style.editorial, 'dtColumnCenter')}>
          <div className={cn(style.editorialPhotos, style.module)}>

          </div>

          <div className={cn(style.activeCoupons, style.module)}>
            <h3 className={style.titleMedium}>Active Coupons</h3>

          </div>
        </div>
      </section> */}

      <section className={cn(style.section, 'rowCenter')}>
        <div className={cn(style.join, style.module, "columnCenter")}>
          <h2 className={cn(style.titleBig, style.centeredTitle)}>Get Snappi</h2>

          <div className={cn(style.joinContainer, "dtRowCenter", "mbColumnCenter")}>
            <input
              className={style.joinInput}
              type="text"
              placeholder="Your email"
              onChange={(e) => setUser(prevUser => ({ ...prevUser, userEmail: e.target.value }))}
            />

            <WithLink to={userId ? routes.USER_DASHBOARD : routes.ONBOARD_ACCOUNT}>
              <Button className={style.joinButton}>
                Join
              </Button>
            </WithLink>
          </div>
        </div>
      </section>

      <section className={cn(style.section, style.departments, 'dtRowCenter', 'mbColumnCenter')}>
        <Link to={"/brands?department=womens"} className={cn(style.womens, style.module, "rowCenter")}>
          <h2 className={style.titleBig}>Women's</h2>
          <img src={Womens} alt="womens" className={style.imageBg} />
        </Link>

        <Link to={"/brands?department=mens"} className={cn(style.mens, style.module, "rowCenter")}>
          <h2 className={style.titleBig}>Men's</h2>
          <img src={Mens} alt="mens" className={style.imageBg} />
        </Link>
      </section>
    </Page >
  )
}