import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import cn from 'classnames'
import {
  signOut
} from "firebase/auth"

import { ModalDispatchContext } from '../../providers/ModalProvider'
import { UserContext } from '../../providers/UserProvider'
import Search from '../Search'

import loading from '../../assets/icons/loadingBlack.svg'
import User from '../../assets/icons/user.svg'
import Logo from '../../assets/icons/snappi.svg'
import downTriangle from '../../assets/icons/downTriangle.svg'
import style from "./style.module.css"
import { auth } from '../..'
import { routes } from '../../utils/constants'

export default function Nav() {
  const navigate = useNavigate()
  const setModal = useContext(ModalDispatchContext)
  const user = useContext(UserContext)
  const { userId, isAdmin, isLoaded } = user
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [isHamburgerMenuOpen, setHamburgerMenuOpen] = useState(false)

  const handleSignOut = async () => {
    try {
      await signOut(auth)
      navigate(routes.LANDING)
      setDropdownOpen(false)
    } catch (error) {
      console.log("Error signing out:", error)
    }
  }

  return (
    <nav className={cn(style.nav, userId ? style.signedIn : style.signedOut)}>
      <div className={style.hamburger} onClick={() => setHamburgerMenuOpen(prev => !prev)}>
        <div className={style.hamburger_line} />
        <div className={style.hamburger_line} />
        <div className={style.hamburger_line} />
      </div>

      {isHamburgerMenuOpen && (
        <>
          <div className={style.hamburgerMenu}>
            <Link
              to={routes.ALL_BRANDS}
              className={style.firstListitem}
              onClick={() => setHamburgerMenuOpen(false)}
            >
              All Brands
            </Link>

            <Search onClose={() => setHamburgerMenuOpen(false)} />
          </div>

          <div className={style.dropdown_cancel} onClick={() => setHamburgerMenuOpen(false)} />
        </>
      )}

      <div className={style.nav_main}>
        <Link to={routes.LANDING} className={style.nav_logo_container}>
          <img
            src={Logo}
            alt="Logo"
            className={style.nav_snappi_logo}
          />
          <h2 className={style.nav_logo}>
            SNAPPI
          </h2>
        </Link>

        <Link to={routes.ALL_BRANDS} className={style.nav_allBrands_link}>
          <h4>Brands List</h4>
        </Link>
      </div>

      <div className={style.search}>
        <Search />
      </div>

      <div className={style.nav_buttons}>
        <div className={style.dropdown_button} onClick={() => setDropdownOpen(!isDropdownOpen)}>
          {userId && isLoaded && (
            <img
              src={User}
              alt="Profile"
              className={style.user}
            />
          )}

          {!isLoaded && (
            <img
              src={loading}
              alt="loading"
              className={style.user}
            />
          )}

          {!userId && isLoaded && <h4>Log in</h4>}

          {isLoaded && <img src={downTriangle} alt="arrow" className={style.downTriangle} />}
        </div>
      </div>

      {isDropdownOpen && (
        <NavDropdown
          handleSignOut={handleSignOut}
          setDropdownOpen={setDropdownOpen}
          isAdmin={isAdmin}
          userId={userId}
          setModal={setModal}
          navigate={navigate}
        />
      )}
    </nav>
  )
}

function NavDropdown({ handleSignOut, setDropdownOpen, isAdmin, userId, setModal }) {
  return (
    <>
      <div className={style.dropdown} onClick={() => setDropdownOpen(false)}>
        <ul>
          {userId ? (
            <>
              <Link to={routes.USER_DASHBOARD}>
                <li className={style.dropDownItemFirst}>
                  Dashboard
                </li>
              </Link>

              <Link to={routes.USER_BILLING}>
                <li>
                  Settings
                </li>
              </Link>

              {isAdmin && (
                <Link to={routes.ADMIN_DASHBOARD}>
                  <li>
                    Admin Dashboard
                  </li>
                </Link>
              )}

              <li className={cn(style.buttonContainer, style.dropDownItemLast)} onClick={handleSignOut}>
                Sign Out
              </li>
            </>
          ) : (
            <>
              <Link to={routes.ONBOARD_ACCOUNT}>
                <li className={style.dropDownItemFirst}>
                  Sign Up
                </li>
              </Link>

              <li onClick={() => setModal({ isOpen: true, type: 'signIn' })} className={style.dropDownItemLast}>
                Sign In
              </li>
            </>
          )}
        </ul>
      </div >

      <div className={style.dropdown_cancel} onClick={() => setDropdownOpen(false)} />
    </>
  )
}