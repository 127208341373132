import { createContext, useContext, useState } from 'react'

const DirtyStateContext = createContext({})

// Custom hook (functional components)
export function useDirtyState() {
  return useContext(DirtyStateContext)
}

export function DirtyStateProvider({ children }) {
  const [isDirty, setDirtyState] = useState(false)
  const [settingsDirty, setSettingsDirty] = useState(false)

  return (
    <DirtyStateContext.Provider value={{ isDirty, setDirtyState, settingsDirty, setSettingsDirty }}>
      {children}
    </DirtyStateContext.Provider>
  )
}
