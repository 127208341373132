import React, { createContext, useEffect, useState } from "react"

const LocalizationContext = createContext(undefined)
const LocalizationDispatchContext = createContext(undefined)

function LocalizationProvider({ children }) {
  const [user, setUser] = useState({
    userRegion: {}
  })

  useEffect(() => {
    // if ("geolocation" in navigator) {
    //   console.log("Available", navigator.geolocation);
    // } else {
    //   console.log("Not Available", navigator.geolocation);
    // }
    return () => ({})
  }, [])

  return (
    <LocalizationContext.Provider value={user}>
      <LocalizationDispatchContext.Provider value={setUser}>
        {children}
      </LocalizationDispatchContext.Provider>
    </LocalizationContext.Provider>
  )
}

export { LocalizationProvider }