import React from 'react'
import Logo from '../../assets/icons/snappiWhite.svg'

import instagram from '../../assets/icons/instagram-white.svg'
import facebook from '../../assets/icons/facebook-white.svg'
import twitter from '../../assets/icons/twitter-white.svg'

import style from "./style.module.css"

const Footer = ({ noFooter }) => !noFooter && (
  <footer>

    <div className={style.content}>
      <div className={style.beta}>
        Snappi is in Beta
      </div>

      <div className={style.logo_container}>
        <img
          src={Logo}
          alt="Logo"
          className={style.snappi_logo}
        />
        <h3 className={style.logo}>
          SNAPPI
        </h3>
      </div>

      {/* <div className={style.socialContainer}>
        <a href="https://instagram.com/trysnappi" target="_href">
          <img src={instagram} alt="instagram" />
        </a>

        <a href="https://instagram.com/trysnappi">
          <img src={facebook} alt="facebook" />
        </a>

        <a href="https://twitter.com/trysnappi" target="_href">
          <img src={twitter} alt="twitter" />
        </a>
      </div> */}

      {/* <p>2023</p> */}
      {/* <p>About Us</p>
      <p>Support</p>
      <p>FAQs</p>
      <p>Privacy Policy</p> */}
    </div>
  </footer>
)

export default Footer