import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import style from "./style.module.css"
import getSales from '../../actions/getSales'
import SaleTile from '../SaleTile'
import SaleForm from '../SaleForm'

export default function EditSaleModal({ brand }) {
  const [activeSales, setActiveSales] = useState([])
  const [inactiveSales, setInactiveSales] = useState([])
  const [editSale, setEditSale] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const {
    brandLogo,
    brandCover,
  } = brand || {}

  useEffect(() => {
    setIsLoading(true)
    const fetchSales = async () => {
      const sales = await getSales(brand.brandId)
      const activeSales = []
      const inactiveSales = []
      sales.forEach(sale => {
        if (sale.saleIsActive) activeSales.push(sale)
        else inactiveSales.push(sale)
      })

      setActiveSales(activeSales)
      setInactiveSales(inactiveSales)
    }
    fetchSales()
    setIsLoading(false)
  }, [])

  return (
    <div className={style.form}>
      {brand && (
        <div className={style.formBrand}>
          <img src={brandCover} className={style.cover} alt={`${brand.brandName}'s cover`} />

          <div className={style.formBrand_details}>
            <img src={brandLogo} className={style.logo} alt={`${brand.brandName}'s logo`} />
            <h3 className={style.brandName}>{brand.brandName}</h3>
          </div>
        </div>
      )}

      {editSale && (
        <div className={style.editForm}>
          <SaleForm brand={brand} editSale={editSale} />
        </div>
      )}

      <div className={style.contentContainer}>
        <label htmlFor="saleDescription">All active sales</label>
        <div className={style.signUp_form}>
          {activeSales.length > 0 && (
            <div className={style.salesContainer}>
              {activeSales.map((sale) => (
                <SaleTile
                  sale={sale}
                  key={sale.saleId}
                  activeSales={activeSales}
                  setActiveSales={setActiveSales}
                  setEditSale={setEditSale}
                  isAdmin
                  isEditForm
                />
              ))}
            </div>
          )}

          {!isLoading && activeSales.length === 0 && (
            <div className={cn(style.salesContainer, style.emptyContainer)}>
              <p className={style.noSales}>No active sales</p>
            </div>
          )}
        </div>
      </div>

      <div className={style.contentContainer}>
        <label htmlFor="saleDescription">All inactive sales</label>
        <div className={style.signUp_form}>
          {inactiveSales.length > 0 && (
            <div className={style.salesContainer}>
              {inactiveSales.map((sale) => (
                <SaleTile
                  sale={sale}
                  key={sale.saleId}
                  activeSales={activeSales}
                  setInactiveSales={setInactiveSales}
                  setEditSale={setEditSale}
                  isInactive
                  isAdmin
                  isEditForm
                />
              ))}
            </div>
          )}

          {!isLoading && inactiveSales.length === 0 && (
            <div className={cn(style.salesContainer, style.emptyContainer)}>
              <p className={style.noSales}>No inactive sales</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}