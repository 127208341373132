import {
  doc,
  updateDoc,
  deleteDoc
} from "firebase/firestore"
import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'
import { convertDateToUnixOfCountry, getTodaysDate } from "../utils/helpers"

export default async function deactivateSale(brandId, saleId, brandRegion) {
  try {
    const brandSaleRef = doc(db, COLLECTIONS.BRANDS, brandId, COLLECTIONS.SALES, saleId)
    const brandRes = updateDoc(brandSaleRef, {
      saleEndDate: convertDateToUnixOfCountry(getTodaysDate(), brandRegion, true),
      saleIsActive: false,
    })

    const activeSaleRef = doc(db, COLLECTIONS.ACTIVE_SALES, saleId)
    const saleRes = deleteDoc(activeSaleRef)

    const res = await Promise.all([brandRes, saleRes])

    return res
  } catch (error) {
    console.log(error)
  }
}