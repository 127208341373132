import React, { useContext } from 'react'
import cn from 'classnames'

import { UserContext, UserDispatchContext } from '../../providers/UserProvider'
import BrandTile from '../BrandTile'

import style from "./style.module.css"
import { FOLLOW_LIMIT } from '../../utils/constants'
import { NotificationDispatchContext } from '../../providers/NotificationProvider'

export default function BrandGrid({
  brands,
  className,
  isSelectGrid,
}) {
  const setNotifications = useContext(NotificationDispatchContext)
  const user = useContext(UserContext)
  const setUser = useContext(UserDispatchContext)
  const { nextFollowing = {}, isAdmin } = user
  const brandsArray = brands ? Object.values(brands) : []

  const setSelected = (nextFollowing) => {
    setUser({
      ...user,
      nextFollowing
    })
  }

  const handleSelected = async (brand) => {
    const { brandId } = brand

    if (nextFollowing[brandId]) {
      const updatedSelected = { ...nextFollowing }
      delete updatedSelected[brandId]
      setSelected(updatedSelected)
    } else {
      if (Object.values(nextFollowing).length >= FOLLOW_LIMIT && !isAdmin) {
        const notification = {
          type: 'warning',
          message: 'Limit 20 brands',
          fadeout: true,
          isActive: true,
        }
        setNotifications(notification)
        return
      }

      setSelected({
        ...nextFollowing,
        [brandId]: brand
      })
    }
  }

  return (
    <div className={cn(style.brands_grid, className)}>
      {brandsArray?.map(b => (
        <div className={style.brands_grid_item} key={b.brandId}>
          <BrandTile
            brand={b}
            isSelected={!!nextFollowing?.[b.brandId]}
            isSelectableTile={isSelectGrid}
            {...(isSelectGrid && { onClick: () => handleSelected(b) })}
          />
        </div>
      ))}
    </div>
  )
}