import {
  collection,
  getDocs,
  query,
  orderBy
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'

export default async function getDrops(brandId) {
  const q = query(collection(db, COLLECTIONS.BRANDS, brandId, COLLECTIONS.DROPS), orderBy("dropDate", "desc"))
  const querySnapshot = await getDocs(q)

  const drops = []
  querySnapshot.forEach((doc) => {
    const data = doc.data()
    data.dropId = doc.id
    drops.push(data)
  })

  return drops
}