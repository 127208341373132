import React, { useContext } from 'react'

import { ModalContext, ModalDispatchContext } from '../../providers/ModalProvider'
import UpdateBrandForm from '../UpdateBrandForm'
import CancelSubscriptionForm from '../CancelSubscriptionForm'
import CreateSaleForm from '../CreateSaleForm'
import SignInUpModal from '../SignInUpModal'
import SwapFollowingForm from '../SwapFollowingForm'
import UpdateCouponsForm from '../UpdateCouponsForm'

import style from "./style.module.css"
import EditSaleModal from '../EditSaleModal'
import CreateDropForm from '../CreateDropForm'

const Modals = () => {
  const { content, type, isOpen, functions, brand } = useContext(ModalContext)
  const setModal = useContext(ModalDispatchContext)

  const handleModalContent = () => {
    switch (type) {
      case ('signUp'):
        return (
          <SignInUpModal
            type={type}
            setModal={setModal}
          />
        )

      case ('signIn'):
        return (
          <SignInUpModal
            type={type}
            setModal={setModal}
          />
        )

      case ('editBrandForm'):
        return (
          <UpdateBrandForm
            type={type}
            setModal={setModal}
            brand={content}
            functions={functions}
          />
        )

      case ('createBrandForm'):
        return (
          <UpdateBrandForm
            type={type}
            setModal={setModal}
            brand={content}
            functions={functions}
          />
        )

      case ('createSaleForm'):
        return (
          <CreateSaleForm
            setModal={setModal}
            brand={content}
          />
        )

      case ('editSaleForm'):
        return (
          <EditSaleModal
            setModal={setModal}
            brand={content}
          />
        )

      case ('createDropForm'):
        return (
          <CreateDropForm
            setModal={setModal}
            brand={content}
          />
        )

      case ('editCoupons'):
        return (
          <UpdateCouponsForm
            setModal={setModal}
            brand={content}
          />
        )

      case ('cancelSubscription'):
        return (
          <CancelSubscriptionForm
            type={type}
            setModal={setModal}
            functions={functions}
          />
        )

      case ('swapFollowing'):
        return (
          <SwapFollowingForm
            type={type}
            setModal={setModal}
            functions={functions}
            brand={brand}
          />
        )

      default:
        return (
          <SignInUpModal
            type={type}
            setModal={setModal}
          />
        )

    }
  }

  return isOpen ? (
    <div className={style.modal_container}>
      <div className={style.modal}>
        {handleModalContent()}
      </div>

      <div
        className={style.modal_overlay}
        onClick={() => setModal({ isOpen: false, type: "" })}
      />
    </div>
  ) : null
}

export default Modals