import React, { useContext, useState } from 'react'
import cn from 'classnames'

import { checkIsFuture, checkIsPast, formatDate } from '../../utils/helpers'
import whiteInfo from '../../assets/icons/whiteInfo.svg'

import style from "./style.module.css"

export default function SaleContent({ sale }) {
  const {
    brandCover,
    saleId,
    saleUrl,
    saleHeader,
    saleCode,
    saleDescription,
    saleEndDate,
    saleStartDate,
    saleImage,
    salePercentage,
    saleIsActive,
    saleType
  } = sale

  const start = formatDate(saleStartDate)

  return (
    <>
      <img
        src={saleImage || brandCover}
        alt="cover"
        className={style.cardImage}
      />

      <p className={style.startDate}>{`STARTED ${start}`}</p>
      <h3 className={style.cardHeader}>{saleHeader}</h3>

      {(saleType === "member" || saleType === "flash") && (
        <div className={cn(style.cardTag, style[saleType], saleType === "member" && style.memberToolTip, "dtRowCenter")}>
          {saleType === "member" && <span className={style.toolTipText}>Member sales usually require signing into an account with the brand</span>}
          <p>{`${saleType} sale`} {saleType === "member" ? <span className={style.infoSpan}><img src={whiteInfo} alt="info" className={style.info} /></span> : ""}</p>
        </div>
      )}
    </>
  )
}