import React, { useContext, useState } from 'react'

import style from "./style.module.css"
import Button from '../Button'
import { UserContext, UserDispatchContext } from '../../providers/UserProvider'
import BrandNugget from '../BrandNugget'
import updateBatchFollowBrands from '../../actions/updateBatchFollowBrands'
import plans from '../../utils/plans'
import { ModalDispatchContext } from '../../providers/ModalProvider'

export default function SwapFollowingForm({ brand: brandToAdd }) {
  const user = useContext(UserContext)
  const setUser = useContext(UserDispatchContext)
  const setModal = useContext(ModalDispatchContext)
  const [toRemove, setToRemove] = useState()
  const { following, plan } = user

  const confirmBrandToSwap = async () => {
    const nextFollowing = { ...following, [brandToAdd.brandId]: brandToAdd }
    delete nextFollowing[toRemove.brandId]
    await updateBatchFollowBrands({ setUser, nextFollowing })
    setModal({ isOpen: false })
  }

  const onCancel = () => {
    setModal({ isOpen: false })
  }

  return (
    <div className={style.form}>
      <h3>You're at Snappi's follow limit of 20 brands</h3>
      <p>Pick a brand you'd like to switch out</p>

      <div className={style.grid}>
        {Object.values(following).map(brand => (
          <BrandNugget
            brand={brand}
            onClick={setToRemove}
            key={brand.brandId}
            isSelected={brand.brandId === toRemove?.brandId}
            disableLink
          />
        ))}
      </div>

      <div className={style.buttonContainer}>
        {toRemove && (
          <Button onClick={() => confirmBrandToSwap()}>
            {`Unfollow ${toRemove.brandName}`}
            <br />
            {`and follow ${brandToAdd.brandName}`}
          </Button>
        )}

        <Button onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  )
}