import React, { useContext, useEffect, useState } from 'react'
import cn from 'classnames'

import BrandBar from '../../components/BrandBar'
import SaleTile from '../../components/SaleTile'
import Page from '../../components/Page'

import { BrandContext, BrandDispatchContext } from '../../providers/BrandsProvider'
import { ModalDispatchContext } from '../../providers/ModalProvider'
import Button from '../../components/Button'
import getAllActiveSales from '../../actions/getAllActiveSales'
import { UserContext } from '../../providers/UserProvider'
import deactivateSale from '../../actions/deactivateSale'

import style from "./style.module.css"
import getAllActiveCoupons from '../../actions/getAllActiveCoupons'
import CouponTile from '../../components/CouponTile'
import getAllUsers from '../../actions/getAllUsers'
import UserTile from '../../components/UserTile'
import updateAlgolia from '../../etc/manipulate/updateAlgolia'

export default function AdminDashboard() {
  const { userRegion } = useContext(UserContext)
  const allBrands = useContext(BrandContext)
  const setModal = useContext(ModalDispatchContext)
  const setBrands = useContext(BrandDispatchContext)
  const [allUsers, setAllUsers] = useState([])
  const [activeTab, setActiveTab] = useState("brands")
  const [allActiveSales, setAllActiveSales] = useState([])
  const [allActiveCoupons, setAllActiveCoupons] = useState([])
  const [usBrands, setUsBrands] = useState([])
  const [ukBrands, setUkBrands] = useState([])
  const [activeBrands, setActiveBrands] = useState([])

  console.log("allBrands", allBrands)

  const handleModal = () => {
    setModal({ isOpen: true, type: 'createBrandForm', functions: { setBrands } })
  }

  useEffect(() => {
    const handleGetAllActiveSales = async () => {
      const sales = await getAllActiveSales()
      setAllActiveSales(Object.values(sales))
    }

    const handleGetAllCoupons = async () => {
      const coupons = await getAllActiveCoupons()
      setAllActiveCoupons(Object.values(coupons))
    }

    const handleGetAllUsers = async () => {
      const users = await getAllUsers()
      setAllUsers(Object.values(users))
    }

    handleGetAllActiveSales()
    handleGetAllCoupons()
    handleGetAllUsers()
  }, [])

  useEffect(() => {
    if (allActiveSales.length === 0) return

    const removeInactiveSales = async () => {
      const inactiveSales = []
      allActiveSales.forEach((sale) => {
        if (sale.saleEndDate < Date.now()) inactiveSales.push(sale)
      })
      const promises = []
      inactiveSales.forEach(({ brandId, saleId, brandRegion }) => {
        const res = deactivateSale(brandId, saleId, brandRegion)
        promises.push(res)
      })
      const res = await Promise.all(promises)
      console.log('Removed inactive sales', res)
    }

    removeInactiveSales()
  }, [allActiveSales])

  useEffect(() => {
    if (!allBrands) return

    const us = []
    const uk = []
    Object.values(allBrands).forEach((brand) => {
      if (brand.brandRegion === "us") us.push(brand)
      else if (brand.brandRegion === "uk") uk.push(brand)
    })
    setUsBrands(us)
    setUkBrands(uk)
    setActiveBrands(prevActiveBrands => prevActiveBrands.length ? prevActiveBrands : us)
  }, [allBrands])

  useEffect(() => {
    if (!allBrands) return
    if (userRegion === "us") setActiveBrands(usBrands)
    else if (userRegion === "uk") setActiveBrands(ukBrands)
  }, [userRegion])

  const updateDB = async () => {
    const res = await updateAlgolia()
    console.log('first res', res)
  }


  return (
    <Page pageClassName={style.page} contentClassName={style.content}>
      <div className={style.sidebar}>
        <p className={style.adminTitle}>ADMIN DASHBOARD</p>

        <div className={style.tabs}>
          <div className={cn(style.tab, activeTab === "brands" && style.activeTab)} onClick={() => setActiveTab("brands")}>
            <p>BRANDS</p>
          </div>

          <div className={cn(style.tab, activeTab === "sales" && style.activeTab)} onClick={() => setActiveTab("sales")}>
            <p>ACTIVE SALES</p>
          </div>

          <div className={cn(style.tab, activeTab === "coupons" && style.activeTab)} onClick={() => setActiveTab("coupons")}>
            <p>ACTIVE CODES</p>
          </div>

          <div className={cn(style.tab, activeTab === "users" && style.activeTab)} onClick={() => setActiveTab("users")}>
            <p>USERS</p>
          </div>

          {/* <div className={cn(style.tab, activeTab === "db" && style.activeTab)} onClick={() => { setActiveTab("db"); updateDB() }}>
            <p>MANIPULATE DB</p>
          </div> */}
        </div>
      </div>

      <div className={style.container}>
        {activeTab === "brands" && (
          <>
            <div className={style.containerHeader}>
              <div className={style.toggleContainer}>
                <div className={cn(style.toggle, activeBrands[0]?.brandRegion === "us" && style.activeToggle)} onClick={() => setActiveBrands(usBrands)}>
                  <h4>US</h4>
                </div>

                <div className={cn(style.toggle, activeBrands[0]?.brandRegion === "uk" && style.activeToggle)} onClick={() => setActiveBrands(ukBrands)}>
                  <h4>UK</h4>
                </div>
              </div>

              <Button onClick={handleModal}>
                Add brand
              </Button>
            </div>

            <div className={style.grid}>
              {activeBrands?.map(b => (
                <BrandBar
                  brand={b}
                  setModal={setModal}
                  setBrands={setBrands}
                  key={b.brandId}
                />
              ))}
            </div>
          </>
        )}

        {activeTab === "sales" && (
          <div className={style.activeSalesContainer}>
            {allActiveSales.map((sale) => (
              <SaleTile
                sale={sale}
                key={sale.saleId}
                activeSales={allActiveSales}
                setActiveSales={setAllActiveSales}
                isAdminDashboard
              />
            ))}
          </div>
        )}

        {activeTab === "coupons" && (
          <div className={style.activeSalesContainer}>
            {allActiveCoupons.map((coupon) => (
              <CouponTile
                coupon={coupon}
                key={coupon.saleId}
                activeSales={allActiveSales}
                setActiveSales={setAllActiveSales}
                isAdminDashboard
              />
            ))}
          </div>
        )}

        {activeTab === "users" && (
          <div className={style.activeSalesContainer}>
            {allUsers.map((user) => (
              <UserTile user={user} key={user.userId} />
            ))}
          </div>
        )}
      </div>
    </Page>
  )
}