import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { auth } from '../..'

import { UserContext } from '../../providers/UserProvider'

export default function UserProtectedRoute({ children }) {
  const { userId, isLoaded } = useContext(UserContext)

  if (!isLoaded) return null
  else if (auth && !userId) return <Navigate to="/" />
  else if (auth && userId) return children
  else <Navigate to="/" />
}