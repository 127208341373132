import React from 'react'

import style from "./style.module.css"
import DropForm from '../DropForm'

export default function CreateDropForm({ brand }) {
  const {
    brandLogo,
    brandCover,
  } = brand || {}

  return (
    <div className={style.form}>
      {brand && (
        <div className={style.formBrand}>
          <img src={brandCover} className={style.cover} alt={`${brand.brandName}'s cover`} />

          <div className={style.formBrand_details}>
            <img src={brandLogo} className={style.logo} alt={`${brand.brandName}'s logo`} />
            <h3 className={style.brandName}>{brand.brandName}</h3>
          </div>
        </div>
      )}

      <DropForm brand={brand} createDrop />
    </div>
  )
}