import {
  collection,
  query,
  getDocs
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'

export default async function getUserFollowing(userId) {
  const array = []

  const followingRef = query(collection(db, COLLECTIONS.USERS, userId, COLLECTIONS.FOLLOWING))
  const followingSnapshot = await getDocs(followingRef)
  const following = {}
  followingSnapshot.forEach((doc) => {
    const brand = doc.data()
    following[doc.id] = brand
    array.push(brand)
  })

  return [following, array]
}