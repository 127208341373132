import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Analytics } from '@vercel/analytics/react'

import Landing from './views/Landing'
import OnboardFollow from './views/OnboardFollow'
import UserDashboard from './views/UserDashboard'
import Settings from './views/Settings'
import BrandDashboard from './views/BrandDashboard'
import AdminDashboard from './views/AdminDashboard'
import AdminRoute from './components/AdminRoute'
import Nav from './components/Nav'
import Modals from './components/Modals'
import ScrollToTop from './components/ScrollToTop'
import OnboardFinish from './views/OnboardFinish'
// import OnboardPlan from './views/OnboardPlan'
// import OnboardPay from './views/OnboardPay'
import OnboardAccount from './views/OnboardAccount'
import NotificationSnackbar from './components/NotificationSnackbar'
// import ChangePlan from './views/ChangePlan'
import { BrandsProvider } from './providers/BrandsProvider'
import AllBrands from './views/AllBrands'
import { routes } from './utils/constants'
import OnboardPick from './views/OnboardPick'
import "./index.css"
import UserProtectedRoute from './components/UserProtectedRoute'
import LandingMessage from './components/LandingMessage'

export default function App() {
  return (
    <>
      {/* <Routes>
        <Route exact path={routes.LANDING} element={<LandingMessage />} />
      </Routes> */}
      <Analytics />

      <Nav />

      <Modals />

      <Routes>
        {/* PUBLIC */}
        <Route exact path={routes.LANDING} element={<Landing />} />
        <Route exact path={routes.ALL_BRANDS} element={<AllBrands />} />
        <Route exact path={routes.BRAND_PROFILE_TEMPLATE} element={
          <BrandsProvider>
            <BrandDashboard />
          </BrandsProvider>
        } />

        {/* ONBOARDING */}
        <Route exact path={routes.ONBOARD_ACCOUNT} element={<OnboardAccount />} />
        <Route exact path={routes.ONBOARD_FOLLOW} element={<OnboardFollow />} />
        {/* <Route exact path={routes.ONBOARD_PLAN} element={
            <UserProtectedRoute>
            <OnboardPlan />} /
            </UserProtectedRoute>> */}
        {/* <Route exact path={routes.ONBOARD_FINISH} element={
            <UserProtectedRoute>
            <OnboardPay />} />
            </UserProtectedRoute> */}
        <Route exact path={routes.ONBOARD_PICK} element={<OnboardPick />} />
        <Route exact path={routes.ONBOARD_FINISH} element={<OnboardFinish />} />

        {/* USER */}
        < Route path={routes.USER_DASHBOARD} element={
          <UserProtectedRoute>
            <UserDashboard />
          </UserProtectedRoute>
        } />
        <Route path={routes.USER_BILLING} element={
          <UserProtectedRoute>
            <Settings />
          </UserProtectedRoute>
        } />
        {/* <Route exact path={routes.CHANGE_PLAN} element={
            <UserProtectedRoute>
            <ChangePlan />} />
            </UserProtectedRoute> */}
        {/* <Route exact path={routes.CHANGE_PLAN_PAYMENT} element={
            <UserProtectedRoute>
            <OnboardPay change
            </UserProtectedRoute>Plan />} /> */}
        {/* <Route exact path={routes.UPDATE_PAYMENT} element={
            <UserProtectedRoute>
            <OnboardPay update
            </UserProtectedRoute>Payment />} /> */}

        {/* ADMIN */}
        <Route exact path={routes.ADMIN_DASHBOARD} element={
          <AdminRoute>
            <BrandsProvider isAdmin>
              <AdminDashboard />
            </BrandsProvider>
          </AdminRoute>
        } />
      </Routes>
      <ScrollToTop />
      <NotificationSnackbar />
    </>
  )
}