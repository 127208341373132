import {
  collection,
  getDocs,
  query,
  orderBy
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'

export default async function getSales(brandId) {
  const q = query(collection(db, COLLECTIONS.BRANDS, brandId, COLLECTIONS.SALES), orderBy("saleStartDate", "desc"))
  const querySnapshot = await getDocs(q)

  const sales = []
  querySnapshot.forEach((doc) => {
    const data = doc.data()
    data.saleId = doc.id
    sales.push(data)
  })

  return sales
}