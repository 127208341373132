import {
  collection,
  query,
  getDocs
} from "firebase/firestore"

import {
  db
} from ".."
import { COLLECTIONS }from '../utils/constants'

export default async function getAllUsers() {
  const allUsers = []

  const q = query(collection(db, COLLECTIONS.USERS))
  const querySnapshot = await getDocs(q)

  querySnapshot.forEach((doc) => {
    const user = doc.data()
    allUsers.push(user)
  })

  return allUsers
}