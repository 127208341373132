import React, { useContext, useState, useRef, useEffect } from 'react'
import cn from 'classnames'

import Button from '../Button'
import { ModalDispatchContext } from '../../providers/ModalProvider'
import createDrop from '../../actions/createDrop'
import updateDrop from '../../actions/updateDrop'
import DropDownMenu from '../DropDownMenu'
import style from "./style.module.css"

export default function DropForm({ brand, editDrop: dropToEdit }) {
  const dropImageRef = useRef()
  const [activeStartDate, setActiveStartDate] = useState("now")
  const [drop, setDrop] = useState({})
  const [editDrop, setEditDrop] = useState(dropToEdit)
  const [isLoading, setIsLoading] = useState(false)
  const [dropImages, setDropImages] = useState([])
  const [preview, setPreview] = useState()

  const {
    brandName,
    brandLogo,
    brandCover,
    brandUrl,
    brandRegion,
    brandId,
  } = brand || {}

  const setModal = useContext(ModalDispatchContext)

  const handlePicInput = (e) => {
    setDropImages([])
    const { files } = e.target
    const validFiles = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      validFiles.push(file)
    }
    setDropImages(validFiles)
  }

  useEffect(() => {
    const images = [], fileReaders = []

    if (dropImages.length) {
      dropImages.forEach((file) => {
        const fileReader = new FileReader()
        fileReaders.push(fileReader)
        fileReader.onload = (e) => {
          const { result } = e.target
          if (result) images.push(result)
          if (images.length === dropImages.length) setPreview(images)
        }
        fileReader.readAsDataURL(file)
      })
    }

    return () => {
      fileReaders.forEach(fileReader => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [dropImages])

  const handleCreateDrop = async () => {
    if (!drop.dropHeader) return alert('Please fill in the drop header')
    if (!drop.dropType) return alert('Please fill in the drop type')
    if (dropImages.length === 0) return alert('Please add images')
    if (!drop.dropDepartments.mens && !drop.dropDepartments.womens) return alert('Please fill in the drop departments')

    setIsLoading(true)

    await createDrop(brandId, {
      ...drop,
      brandName,
      brandCover,
      brandLogo,
      brandId,
      brandUrl,
      brandRegion,
    }, dropImages, activeStartDate)

    setModal({ isOpen: false })
    setIsLoading(false)
  }

  const handleEditDrop = async () => {
    // // check dirty state
    // setIsLoading(true)

    // // TODO: can't edit start date, dropImages
    // // DON'T CHANGE: brandName, brandCover, brandLogo, brandUrl, brandRegion, brandId
    // await updateDrop(brandId, editDrop)

    // setModal({ isOpen: false })
    // setIsLoading(false)
  }

  const handleUpdateDrop = editDrop ? setEditDrop : setDrop
  const handleSaveDrop = editDrop ? handleEditDrop : handleCreateDrop
  const headerText = editDrop ? 'Edit Drop' : 'Create Drop'
  const buttonText = editDrop ? 'Update drop' : 'Create drop'

  return (
    <>
      <div className={style.signUp_form}>
        <h3>{headerText}</h3>

        {!editDrop && (
          <div className={style.uploadContainer}>
            <div className={style.previewGrid}>
              {preview?.length > 0 ?
                preview.map((preview, i) => (
                  <div className={style.previewContainer} key={dropImages[i].name}>
                    <img className={style.pic_preview} src={preview} alt="cover" />
                    <p>{dropImages[i].name}</p>
                  </div>
                ))
                : <div className={style.pic_preview} />}
            </div>

            <Button className={style.filePickerButton} onClick={() => dropImageRef.current.click()}>
              Add drop images (Try limit to 8)
            </Button>

            <input
              hidden
              ref={dropImageRef}
              className={style.imageUpload}
              onChange={handlePicInput}
              accept="image/*"
              type="file"
              multiple
            />

          </div>
        )}

        <label htmlFor="dropType">Type of drop*</label>
        <DropDownMenu
          className={cn(style.input)}
          keyName="dropType"
          keyDisplayName="drop type"
          onClick={(key, value) => handleUpdateDrop(prev => ({
            ...prev,
            [key]: value
          }))}
          selectedValue={editDrop?.dropType || drop.dropType}
          options={[{
            name: "Collection",
            value: "collection",
          },
          {
            name: "Product",
            value: "product",
          }]}
        />

        <label htmlFor="saleType">Department</label>
        <div className={cn(style.departmentContainer, "dtRowCenter")}>
          <div className={cn(style.department, "dtRowCenter")}>
            <input
              type="checkbox"
              id="mens"
              name="mens"
              value="true"
              onChange={e => handleUpdateDrop(prevSale =>
              ({
                ...prevSale,
                dropDepartments: {
                  ...prevSale.dropDepartments,
                  mens: !prevSale.dropDepartments?.mens,
                }
              }))}
            />
            <label htmlFor="mens">Men's</label><br />
          </div>

          <div className={cn(style.department, "dtRowCenter")}>
            <input
              type="checkbox"
              id="womens"
              name="womens"
              value="true"
              onChange={e => handleUpdateDrop(prevSale =>
              ({
                ...prevSale,
                dropDepartments: {
                  ...prevSale.dropDepartments,
                  womens: !prevSale.dropDepartments?.womens,
                }
              }))}
            />
            <label htmlFor="womens">Women's</label><br />
          </div>
        </div>

        <label htmlFor="header">Drop header*</label>
        <input
          name="header"
          type="text"
          {...editDrop && { defaultValue: editDrop.dropHeader }}
          onChange={e => handleUpdateDrop((prev) => (({
            ...prev,
            dropHeader: e.target.value,
          })))}
        />

        <label htmlFor="description">Drop description</label>
        <input
          name="description"
          type="text"
          {...editDrop && { defaultValue: editDrop.dropDescription }}
          onChange={e => handleUpdateDrop((prev) => (({
            ...prev,
            dropDescription: e.target.value,
          })))}
        />

        <label htmlFor="generalUrl">General Drop URL (Will default to brand home page if empty)</label>
        <input
          name="generalUrl"
          type="text"
          {...editDrop && { defaultValue: editDrop.dropUrl?.general }}
          onChange={e => handleUpdateDrop((prev) => ({
            ...prev,
            dropUrl: {
              ...prev.dropUrl,
              general: e.target.value,
            }
          }))}
        />

        <label htmlFor="womensUrl">Women's Drop URL</label>
        <input
          name="womensUrl"
          type="text"
          {...editDrop && { defaultValue: editDrop.dropUrl?.womens }}
          onChange={e => handleUpdateDrop((prev) => ({
            ...prev,
            dropUrl: {
              ...prev.dropUrl,
              womens: e.target.value,
            }
          }))}
        />

        <label htmlFor="mensUrl">Men's Drop URL</label>
        <input
          name="mensUrl"
          type="text"
          {...editDrop && { defaultValue: editDrop.dropUrl?.mens }}
          onChange={e => handleUpdateDrop((prev) => ({
            ...prev,
            dropUrl: {
              ...prev.dropUrl,
              mens: e.target.value,
            }
          }))}
        />

        {!editDrop && (
          <>
            <label htmlFor="dropDate">Drop date*</label>
            <div className={style.dropDate}>
              <div
                onClick={() => setActiveStartDate("now")}
                className={cn(activeStartDate === "now" && style.activeStartDate)}
              >
                Now
              </div>
              <div
                onClick={() => setActiveStartDate("else")}
                className={cn(activeStartDate === "else" && style.activeStartDate)}
              >
                Else
              </div>
            </div>
          </>
        )}

        {(activeStartDate === "else" || editDrop) && (
          <input
            name="dropDate"
            type="date"
            defaultValue={drop.dropDate}
            onChange={e => handleUpdateDrop((prev) => ({
              ...prev,
              dropDate: e.target.value,
            }))}
          />
        )}
      </div>

      <Button
        onClick={handleSaveDrop}
        className={style.button}
        isLoading={isLoading}
      >
        {buttonText}
      </Button>
    </>
  )
}